export default {
	all: state => Object.keys(state.all).map(key => state.all[key]),
	jumpRules: (state, getters, rootState, rootGetters) => (jumpGroups) => {
		let rulesList = []
		let groups = rootGetters[`${getters.editSource.ruleGroup}/jumpRuleGroups`](jumpGroups)	
		groups.forEach(group => {
			let rules = getters.groupRules(group.jump_rules)
			rules.forEach(rule => rulesList.push(rule))
		})
		return rulesList
	},
	groupRules: (state) => (groupRules) => {
		 return groupRules.map(id => state.all[id])
	}, 
	/**
	 * Used with nested jumps and groups. Options key is set to options_list
	 *
	 * @param {Array} groupRules 
	 * @return {Array} 
	 */
	nestedRules: (state, getters) => (groupRules) => {
		let rules = getters['groupRules'](groupRules)
		return rules.map(rule => {
			const list = rule.options
			return {
				...rule,
				options_list: list
			}
		})
	}, 
	notValid: state => state.notValid,
	hasInvalidValue: (state) => (rule_id) => state.notValid.includes(rule_id)
}
