import actions from './actions'
import mutations from './mutations'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: []
	},
	getters: {
		all: state => state.all,
		getById: (state) => (id) => {
			const target = state.all.find(target => target.id === id)
			return target ? target : null
		}
	},
	mutations: mutations,
	actions: actions,
	modules: {
		editing: editing
	}
}
