import CountryApi from '@/services/CountryApi'

const state = {
  all: []
}

const getters = {
  all (state) {
  	return state.all
  }
}

const mutations = {
	setAll (state, countries) {
		state.all = countries
  }
}

const actions = {
	async fetchAll ({ commit }) {
		try {
			const countries = await CountryApi.all()
			commit('setAll', countries)
		} catch (err) {
			throw err
		}
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}

