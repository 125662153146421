import mixinMutations from '@/store/mixins/option/mutations'
import mixinActions from '@/store/mixins/option/actions'
import mixinGetters from '@/store/mixins/option/getters'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: [],
		notValid: {
			fi: [],
			en: []
		}
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.survey
		}
	},
  mutations: mixinMutations,
	actions: {
		...mixinActions
	}
}
