import SurveyApi from '@/services/api/SurveyApi'
import Normalizer from '@/services/Normalizer'

export default {
	/**
	 * Get requested survey
	 * @param {String} 
	 */
	async getSurvey ({ dispatch, commit }, id) {
		commit('setLoading', true)
		try {
			const survey = await SurveyApi.show(id)
			dispatch('setSurveyData', survey)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	/**
	 * TODO Problem with chrome?
	 * Set autfocus for segment title and remove it
	 */
	focusTitle ({ commit }) {
		commit('toggleTitleFocus', true)
		setTimeout(() => {
			commit('toggleTitleFocus', false)
		}, 500)
	},
	/**
	 * Update survey's data 
	 * @param {Object} data
	 */
	async updateSurveyData ({ state, commit }) {
		const surveyData = await SurveyApi.update(state.data.id, state.data)
		//TODO return and set
	},
	/**
	 * Update survey's segments, fields and options 
	 */
	async updateParts ({ state, getters, commit, dispatch  }) {
		const data = {
			segments: state.segment.all,
			segment_order_list: state.segment.segmentList,
			removed_segments: state.segment.removed,
			fields: state.field.all,
			removed_fields: state.field.removed,
			options: state.option.all,
			removed_options: state.option.removed,
			jumps: state.jump.all,
			removed_jumps: state.jump.removed,
			rule_groups: state.jump.ruleGroup.all,
			removed_rule_groups: state.jump.ruleGroup.removed,
			rules: state.jump.rule.all,
			removed_rules: state.jump.rule.removed,
			lottery_active:state.data.lottery_active
		}
		dispatch(`field/removeExtraOptions`)
		const survey = await SurveyApi.updateParts(state.data.id, data)
		dispatch('setSurveyData', survey)
	},

	async updateStyles ({ state, getters, commit, dispatch  }) {
		const survey = await SurveyApi.updateStyles(state.data.id, {
			theme_color: state.data.theme_color
		})
	},
	
	/**
	 * Select upload preview image 
	 * @param {} 
	 */
	async imageSelect ({ state, commit }, data) {
		try {
			const imageData = await SurveyApi.uploadImage(state.data.id, data)
			commit('editValue', {
				key: 'image_preview_path',
				value: imageData.image_preview_path
			})
			commit('editValue', {
				key: 'image_preview_name',
				value: imageData.image_preview_name
			})
		} catch (e) {
			console.log(e) 
		}
	},

	/**
	 * set lotteryactive 
	 * @param {} 
	 */
		lotteryActive ({ state, commit }, data) {
				commit('editValue', {
					key: 'lottery_active',
					value: data})
		},

		/**
	 * Select upload preview image 
	 * @param {} 
	 */
		 async bottomImageSelect ({ state, commit }, data) {
			try {
				const imageData = await SurveyApi.uploadBottomImage(state.data.id, data)
				commit('editValue', {
					key: 'image_preview_path',
					value: imageData.image_preview_path
				})
				commit('editValue', {
					key: 'image_preview_name',
					value: imageData.image_preview_name
				})
			} catch (e) {
				console.log(e) 
			}
		},

			/**
	 * Select upload preview image 
	 * @param {} 
	 */
	async finishImageSelect ({ state, commit }, data) {
		try {
			const imageData = await SurveyApi.uploadFinishImage(state.data.id, data)
			commit('editValue', {
				key: 'image_preview_path',
				value: imageData.image_preview_path
			})
			commit('editValue', {
				key: 'image_preview_name',
				value: imageData.image_preview_name
			})
		} catch (e) {
			console.log(e) 
		}
	},

	/**
	 * Remove preview image
	 * @param {} 
	 */
	 async removePreviewImage ({ state, commit }) {
		try {
			await SurveyApi.removePreviewImage(state.data.id)
			commit('editValue', {
				key: 'image_preview_path',
				value: null			
			})
			commit('editValue', {
				key: 'image_preview_name',
				value: null 
			})
		} catch (e) {
			console.log(e) 
		}
	},	/**
	* Remove bottom image
	* @param {} 
	*/
   async removeBottomImage ({ state, commit }) {
	   try {
		   await SurveyApi.removeBottomImage(state.data.id)
		   commit('editValue', {
			   key: 'bottom_image_path',
			   value: null			
		   })
		   commit('editValue', {
			   key: 'bottom_image_name',
			   value: null 
		   })
	   } catch (e) {
		   console.log(e) 
	   }
   },	/**
   * Remove finish image
   * @param {} 
   */
  async removeFinishImage ({ state, commit }) {
	  try {
		  await SurveyApi.removeFinishImage(state.data.id)
		  commit('editValue', {
			  key: 'finish_image_path',
			  value: null			
		  })
		  commit('editValue', {
			  key: 'finish_image_name',
			  value: null 
		  })
	  } catch (e) {
		  console.log(e) 
	  }
  },

}
