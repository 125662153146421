import actions from './actions'

export default {
	namespaced: true,
	state: {
		translations: [],
	},
	getters: {
		translations: state => state.translations
  },
  mutations: {
    setTranslations(state, translations) {
      state.translations = translations
    }
  },
  actions: actions
}
