export default {
	namespaced: true,
	state: {
		show: false,
	},
	getters: {
		show: state => state.show 
	},
	mutations: {
		/**
		 * Toggle overlay loader
		 * @param {Boolean} value 
		 */
		toggleLoader(state, value) {
		 state.show = value
		},
	},
}
