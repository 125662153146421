import mixinMutations from '@/store/mixins/common-segment/mutations'
import mixinActions from '@/store/mixins/common-segment/actions'
import field from './field'
import option from './option'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: {},
		creating: {
			title: ''
		}
	},
	getters: {
		all: state => Object.keys(state.all).map(key => state.all[key]),
		get: (state) => (id) => state.all[id],
		creating: state => state.creating
  },
	mutations: {
		...mixinMutations
	},
	actions: {
		...mixinActions
	},
	modules: {
		field: field,
		option: option,
		editing: editing
	}
}
