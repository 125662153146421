import SurveyTemplateApi from '@/services/api/SurveyTemplateApi'
import Normalizer from '@/services/Normalizer'

export default {
	/**
	 * Get requested template 
	 * @param {String} 
	 */
	async getData ({ dispatch, commit }, id) {
		commit('setLoading', true)
		try {
			const template = await SurveyTemplateApi.show(id)
			dispatch('setSurveyData', template)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	/**
	 * TODO Problem with chrome?
	 * Set autfocus for segment title and remove it
	 */
	focusTitle ({ commit }) {
		commit('toggleTitleFocus', true)
		setTimeout(() => {
			commit('toggleTitleFocus', false)
		}, 500)
	},
	/**
	 * Update template's data 
	 * @param {Object} data
	 */
	async updateSurveyData ({ state, commit }) {
		const surveyData = await SurveyTemplateApi.update(state.data.id, state.data)
	},
	/**
	 * Update template's segments, fields and options 
	 */
	async updateParts ({ state, commit, dispatch }) {
		const data = {
			segments: state.segment.all,
			segment_order_list: state.segment.segmentList,
			removed_segments: state.segment.removed,
			fields: state.field.all,
			removed_fields: state.field.removed,
			options: state.option.all,
			removed_options: state.option.removed,
			jumps: state.jump.all,
			removed_jumps: state.jump.removed,
			rule_groups: state.jump.ruleGroup.all,
			removed_rule_groups: state.jump.ruleGroup.removed,
			rules: state.jump.rule.all,
			removed_rules: state.jump.rule.removed
		}
		dispatch(`field/removeExtraOptions`)
		const template = await SurveyTemplateApi.updateParts(state.data.id, data)
		dispatch('setSurveyData', template)
	},
}
