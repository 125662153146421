import mixinMutations from '@/store/mixins/survey/editing/mutations'
import mixinActions from '@/store/mixins/survey/editing/actions'
import mixinGetters from '@/store/mixins/survey/editing/getters'
import actions from './actions'
import segment from './segment'
import field from './field'
import option from './option'
import jump from './jump'
import preview from './preview'

export default {
	namespaced: true,
	state: {
		data: {},
		page_index: null,
		loading: false,
		gap_open_index: null,
		focus_title: false,
		locale: 'fi'
	},
	getters: {
		showing: state => state.data,
		loading: state => state.loading,
		surveyTarget (state, getters, rootState, rootGetters) {
			return rootGetters['surveyTarget/getById'](state.data.survey_target_id)	
		},
		...mixinGetters
  },
	mutations: {
		...mixinMutations,
		...{
			/**
			 * Set base data to be edited
			 * @param {Object} data 
			 */
			setData(state, data) {
				state.data = data
				if (data.hasOwnProperty('translations') && data.translations.length > 0) {
					let translations = {}
					data.translations.forEach(translation => {
						translations = {
							...translations,
							[translation.locale]: {
								start_text: translation.start_text,
								end_text: translation.end_text
							}
						}	
					})
					state.data = {
						...data,
						translations: translations
					}
				} else {
					state.data = {
						...data,
						translations: {
							fi: {},
							en: {}
						}
					}
				}
			},	
		}
	},
	actions: {
		...mixinActions,
		...actions
	},
	modules: {
		segment: segment,
		field: field, 
		option: option,
		jump: jump,
		preview: preview
	}
}

