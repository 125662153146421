import SurveyTargetApi from '@/services/api/SurveyTargetApi'

export default {
	/**
	 * Get user's survey targets 
	 */
	async all ({ dispatch, commit }) {
		try {
			const targets = await SurveyTargetApi.all()
			commit('all', targets)
			dispatch('survey/setAll', targets, { root: true })
		} catch (err) {
			console.log(err)
		}
	},
	/**
	 * Delete chosen survey target
	 * @param {String} target_id
	 */
	async remove ({ dispatch, commit }, target_id) {
		try {
			const target = await SurveyTargetApi.delete(target_id)
			return target
		} catch (err) {
			console.log(err)
		}
	}
}
