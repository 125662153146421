export default {
	/**
	 * Set survey's base data 
	 * @param {Object} data 
	 */
	setData(state, data) {
		state.data = data	
	},
	/**
	 * Set segments
	 * @param {Object} segments 
	 */
	setSegments(state, segments) {
		state.segments = segments
	},
	/**
	 * Set fields
	 * @param {Object} fields 
	 */
	setFields(state, fields) {
		state.fields = fields
	},
	/**
	 * Set options
	 * @param {Object} options 
	 */
	setOptions(state, options) {
		state.options = options
	},
	/**
	 * Set jumps
	 * @param {Object} jumps 
	 */
	setJumps(state, jumps) {
		state.jumps = jumps
	},
	/**
	 * Set jump rule groups
	 * @param {Object} jump_rule_groups 
	 */
	setJumpRuleGroups(state, jump_rule_groups) {
		state.jumpRuleGroups = jump_rule_groups
	},
	/**
	 * Set rules
	 * @param {Object} rules 
	 */
	setRules(state, rules) {
		state.jumpRules = rules
	},
}
