import { v4 as uuidv4 } from 'uuid';

//TODO set only translations that are given to survey
const template = {
	label: '',
	is_required: false,
	answer_type: 'input',
	answer_type_id: 1,
	options: [],
	translations: {
		fi: {
			label: null
		},
		en: {
			label: null
		}
	}
}

export default {
	/**
	 * Set fields 
	 * @param {Object} fields
	 */
	setFields ({ commit }, fields) {
		commit('setFields', fields)
		commit('clearRemoveList')
		commit('clearNotValidList')
	},
	/**
	 * Add a new field to the segment 
	 * @param {String} segment_id 
	 */
	add ({ commit, getters }, segment_id) {
		const field = {
			id: uuidv4(),
			segment: segment_id,
			..._.cloneDeep(template)
		}
		commit('add', field)
		commit(`${getters.editSource.segment}/addField`, {
			segment: segment_id,
			field: field.id
		}, { root: true })
	},
	/**
	 * Add copy from the existing field 
	 * @param {Object} payload
	 */
	addCopy ({ state, getters, commit, dispatch }, payload) {
		const originalField = state.all[payload.field_id]
		const field = {
			..._.cloneDeep(originalField),
			id: uuidv4(),
			segment: payload.segment_id,
			options: []
		}
		commit('add', field)
		commit(`${getters.editSource.segment}/addField`, {
			segment: payload.segment_id,
			field: field.id
		}, { root: true })
		originalField.options.forEach(option => {
			//TODO children options
			dispatch(`${getters.editSource.option}/addCopy`, {
				option_id: option,
				field_id: field.id
			}, {root: true})
		})
	},
	/**
	 * Remove field and field's options 
	 * @param {String} field_id 
	 */
	remove ({ state, getters, commit, dispatch }, field_id) {
		const field  = state.all[field_id]
		field.options.forEach(option_id => {
			dispatch(`${getters.editSource.option}/remove`, option_id, { root: true })
		}) 
		commit(`${getters.editSource.segment}/removeField`, field, { root: true })
		commit('remove', field_id)	
		commit('removeFromNotValid', field_id)
	},
	/**
	 * Remove all the options from the field 
	 * @param {String} field_id
	 */
	removeOptions ({ state, getters, dispatch }, field_id) {
		const field  = state.all[field_id]
		let options = [...field.options]
		options.forEach(option_id => {
			dispatch(`${getters.editSource.option}/remove`, option_id, { root: true })
		}) 
	},
	/**
	 * Remove all the child options from the field 
	 * @param {String} field_id
	 */
	removeChildOptions ({ state, getters, rootGetters, dispatch }, field_id) {
		const field  = state.all[field_id]
		let options = [...field.options]
		options.forEach(option_id => {
			const option = rootGetters[`${getters.editSource.option}/get`](option_id) 
			if (option.parent_id !== null) {
				dispatch(`${getters.editSource.option}/removeChild`, option_id, { root: true })
			}
		}) 
	},
	/**
	 * Remove options from the fields, if field type is not select or multiselect 
	 */
	removeExtraOptions ({ state, dispatch }) {
		const fields = Object.values(state.all)
		fields.forEach(field => {
			if (field.answer_type === 'multiselect') {
				dispatch('removeChildOptions', field.id)
			} else if (field.answer_type !== 'select') {
				dispatch('removeOptions', field.id)	
			} 
		})
	},
	/**
	 * Edit field's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue({state, commit, getters, rootGetters}, payload) {
		commit('editTranslationValue', {
			field: payload.field,
			key: payload.key,
			value: payload.value,
			locale: rootGetters[`${getters.editSource.base}/locale`]
		})
	},
	/**
	 * Restory removed fields 
	 * @param {Array} fields
	 */
	restoreFields ({ commit, dispatch }, fields) {
		fields.forEach(field => {
			commit('restoreField', field)
		})		
		dispatch('validateFields')
	},
	/**
	 * Validate all survey's/template's fields 
	 */
	validateFields ({ state, commit }, languages) {
		// Languages are passed as extran parameter
		// if languages are not given fallback
		if(!Array.isArray(languages) || !languages.length) {
			languages = ['fi', 'en'];
		}

		// Remove languages outside scope
		Object.keys(state.notValid).forEach(key => {
			if(!languages.includes(key)) {
				commit('removePropertyFromNotValid', key)
			}
		})

		Object.values(state.all).forEach(field => {
			languages.forEach(language => {
				if(!field.translations[language] || field.translations[language].label === '' || field.translations[language].label === null) {
					commit('addToNotValid', {
						field: field.id,
						locale: language
					})
				}
			})
		})
	},
}
