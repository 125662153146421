<template>
  <v-slide-y-transition>
    <div
      class="notifications"
      v-show="showing"
    >
  <v-card
    v-for="(notification, index) in notifications"
    :key="index"
    min-width=320
    tile
    dark
    dismissiable
    class="notification-card py-2 px-4 body-2 mb-2" 
    :class="[
      {secondary: notification.type === 'success'},
      {red: notification.type === 'error'},
      {orange: notification.type === 'warning'}
    ]"
  >
    <div class="d-flex align-center justify-space-between">
      <v-icon 
        v-if="notification.type === 'success'"
        color="green"
      >mdi-check</v-icon>
      <v-icon 
        v-else-if="notification.type === 'error' || notification.type === 'warning'"
        color="white"
      >mdi-exclamation-thick</v-icon>
      {{notification.text}} 
      <div class="notification-actions ml-2">
        <v-btn 
          v-if="notification.showUndo"
          text 
          small 
          class="primary--text mr-2"
          @click="undo(notification.undoAction)"
        >
          Peru
        </v-btn>
        <v-btn text small fab icon class="white--text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
  </div>
  </v-slide-y-transition>
</template>
<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
  name: 'Notifications',
  computed: {
    ...mapGetters('notification', {
      notifications: 'current',
      showing: 'showing'
    }),
  },
  methods: {
    ...mapActions('notification', {
      undo: 'undoAction'
    }),
    close () {
      this.$store.commit('notification/toggleNotifications', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.notifications {
  position: fixed;
  right: 10px;
  top: 10px;
}
</style>
