import mutations from './mutations'
import actions from './actions'
import surveyViewer from './survey-viewer'
import templateViewer from './template-viewer'

export default {
	namespaced: true,
	state: {
		dialog: false,
		data: {},
		chosen_target: {},
		new_target_data: {
			name: null,
			target_type_id: null
		},
		is_creating_target: false,
		template_option: 'template',
		fetching_viewer_data: false
	},
	getters: {
		shownTarget: state => state.is_creating_target 
			? state.new_target_data
			: state.chosen_target,
		isCreatingTarget: state => state.is_creating_target,
		templateOption: state => state.template_option,
		shownSurvey: (state) => {
			if (state.template_option === 'template') {
				return state.chosen_template
			} else if (state.template_option === 'survey') {
				return state.chosen_survey
			} else {
				return {}
			}
		},
		chosenTemplate: state => state.chosen_template,
		fetchingViewerData: state => state.fetching_viewer_data
	},
	mutations: mutations,
	actions: actions,
	modules: {
		surveyViewer: surveyViewer,
		templateViewer: templateViewer
	}
}
