import Http from '../../../services/Http'

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: null
  },
  getters: {
		authenticated: state => state.authenticated,
		user: state => state.user
  },
  mutations: {
		/**
		 * Set if user is authenticatd
		 * @param {Boolean} value 
		 */
		setAuthenticated(state, value) {
			state.authenticated = value
		},
		/**
		 * Set currently authenticted user's data
		 * @param {Object} value 
		 */
		setUser(state, value) {
			state.user = value
		},
  },
  actions: {
		/**
		 * Sign in using laravel sanctum 
		 * @param {Object} credentials
		 */
		async signIn({ dispatch }, credentials) {
			try {
				await Http.get('/sanctum/csrf-cookie')
				await Http.post('/login', credentials)
				await dispatch('me')
			} catch(err){
				throw err
			}
		},		
		/**
		 * Sign out using laravel sanctum 
		 * @param {Object} credentials
		 */
		async signOut({ dispatch, commit }) {
			try {
				await Http.post('/logout')
			//TODO reset state 
        commit('setAuthenticated', false)
        commit('setUser', null)
				this.reset()
			} catch(err) {
				throw err
			}
		},	
		/**
		 * Get user's data 
		 */
		async me ({ commit }) {
			try {
				const response = await  Http.get('api/user')
        commit('setAuthenticated', true)
				commit('setUser', response.data)
			} catch {
				await Http.post('/logout')
        commit('setAuthenticated', false)
        commit('setUser', null)
				this.reset()
				throw err
			}
		},
  }
}
