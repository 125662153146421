export default {
	/**
	 * Show given notifications 
	 * @param {Array} payload 
	 */
	showNotifications ({ state, commit }, payload) {
		let notifications = payload
		let timeout = 4500
		notifications.map(notification => {
			let obj = notification
			if (!notification.hasOwnProperty('showUndo')) {
				obj = {
					...notifications, 
					showUndo: false, 
					undoAction: ''
				}
			} else {
				if (notification.showUndo) {
					timeout = null 
				}
			} 
			return obj
		})
		commit('setNotifications', notifications)
		if (state.showing) {
			commit('toggleNotifications', false)
			setTimeout(() => {
				commit('toggleNotifications', true)
			}, 200)
		} else {
			commit('toggleNotifications', true)
		}
		if (timeout) {
			setTimeout(() => {
				commit('toggleNotifications', false)
			}, timeout)
		}
	},
	/**
	 * Do set undo action 
	 * @param {String} action
	 */
	undoAction ({ state, commit,  dispatch}, action) {
		if (action === 'returnSurveySegment') {
			dispatch('survey/editing/segment/returnLastlyRemoved', null, { root: true })	
		} else if (action === 'returnTemplateSegment') {
			dispatch('surveyTemplate/editing/segment/returnLastlyRemoved', null, { root: true })	
		} else {
			dispatch('surveySection/editing/segment/returnLastlyRemoved', null, { root: true })	
		}	
		commit('toggleNotifications', false)
	},
}
