export default {
	/**
	 * Combine sections' segments
	 * @param {Array} sections 
	 */
	setSegments(state, sections) {
		let segments = []
		sections.forEach(section => {
			segments = [...segments, ..._.cloneDeep(section.segments)]
		})
		state.segments = segments
	},
}
