export default {
	all: state => state.all,
	get: state => field_id => state.all[field_id],
	segmentFields: (state) => (segmentFields) => {
		return segmentFields.map(id => state.all[id])
	},
	/**
	 * Return fields with nested options 
	 * @return {return type} 
	 */
	withOptions: (state, getters, rootState, rootGetters) => (segmentFields) => {
		const source = getters.editSource
		const fields = _.cloneDeep(getters.segmentFields(segmentFields))
		return fields.map(field => {
			const fieldOptions = _.cloneDeep(rootGetters[`${source.option}/itemOptionsPreview`](field.options))
			const translations = Object.keys(field.translations).map(locale => {
				return {
					..._.cloneDeep(field.translations[locale]),
					locale: locale
				}
			})
			return {
				...field,
				options: fieldOptions,
				translations: translations
			}
		})
	},
	invalidValues: state => state.notValid,
	hasInvalidValues (state) {
		let hasInvalidValues = false
		Object.values(state.notValid).forEach(locale => {
			if (locale.length > 0) hasInvalidValues = true
		})
		return hasInvalidValues
	},
	hasInvalidValue: (state) => (locale, field_id) => {
		if(!state.notValid.hasOwnProperty(locale)) return false
		return state.notValid[locale].includes(field_id)
	},
	notValidSegmentFields: (state) => (segmentFields, locale) => {
		if(!state.notValid.hasOwnProperty(locale)) return []
		return state.notValid[locale].filter(field_id => {
			return segmentFields.includes(field_id)
		})
	},
	segmentFieldsObject: (state) => (segmentFields) => {
		let fields = {}
		segmentFields.forEach(id => {
			fields = {
				...fields,
				[id]: state.all[id]
			}	
		})
		return fields
	}
}
