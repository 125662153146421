import Http from '@/services/Http'

export default {
  async all () {
    try {
      const response = await Http.get('api/translations') 
      return response.data
    } catch (err) {
      throw err
    }
  },
}
