export default {
	start: 'Aloita',
	next: 'Seuraava',
	previous: 'Edellinen',
	continue: 'Jatka',
	cancel: 'Keskeytä',
	participate: 'Osallistu',
	terms: 'Käyttöehdot',
	privacy: 'Yksityisyys'
}
