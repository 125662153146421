import buttons from './buttons/en'
import labels from './labels/en'
import messages from './messages/en'
import texts from './texts/en'
import dialogs from './dialogs/en'

export default {
	buttons: buttons,
	labels: labels,
	messages: messages,
	texts: texts,
	dialogs: dialogs,
}
