import { v4 as uuidv4 } from 'uuid';

export default {
	/**
	 * Set segments 
	 * @param {Object} segments
	 */
	setSegments ({ commit }, segments) {
		commit('setSegments', segments)	
		commit('clearRemoveList')
		commit('clearNotValidList')
	},
	/**
	 * Remove segment and segment's fields 
	 * @param {String} segment_id
	 */
	remove ({ state, getters, commit, dispatch }, segment_id) {
		const fields = state.all[segment_id].fields
		dispatch('saveForUndo', _.cloneDeep(state.all[segment_id]))
		fields.forEach(field_id => {
			dispatch(`${getters.editSource.field}/remove`, field_id, { root:true })
		})
		commit('remove', segment_id)
		commit('removeFromNotValid', segment_id)
	},
	/**
	 * Create copy from the existing segment 
	 * @param {String} segment_id original segment id
	 */
	createCopy ({ state, getters, commit, dispatch }, segment_id) {
		const originalSegment = {...state.all[segment_id]}	
		const id = uuidv4()
		let copySegment = {
			..._.cloneDeep(originalSegment),
			id: id,
			new: true,
			fields: [],
			translations: {
				en: {
					title: `${originalSegment.translations.en.title} (copy)`
				},
				fi: {
					title: `${originalSegment.translations.fi.title} (kopio)`
				}
			}
		}
		commit('addCopy', {
			copy: copySegment,
			original: originalSegment
		})
		originalSegment.fields.forEach(field => {
			dispatch(`${getters.editSource.field}/addCopy`, {
				field_id: field,
				segment_id: copySegment.id
			}, {root: true})
		})
	},
	/**
	 * Edit segment's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue({state, commit, getters, rootGetters}, payload) {
		commit('editTranslationValue', {
			segment: payload.segment,
			key: payload.key,
			value: payload.value,
			locale: rootGetters[`${getters.editSource.base}/locale`]
		})
	},
	/**
	 * Add common segment to the survey/template segments 
	 * @param {Object} payload
	 */
	addCommonSegment ({ commit, rootGetters }, payload) {
		let segment = rootGetters['commonSegment/get'](payload.segment_id)	
		commit('addCommonSegment', {
			afterSegment: payload.afterSegment_id,
			commonSegment: segment
		})
	},
	/**
	 * Save segment, it's fields and fields' options for undo 
	 * @param {Object} segment
	 */
	saveForUndo ({ state, getters, commit, rootGetters }, segment) {
		let fields = []
		let options = []
		let index = state.segmentList.indexOf(segment.id)
		let segmentFields = _.cloneDeep(rootGetters[`${getters.editSource.field}/segmentFields`](segment.fields))
		segmentFields.forEach(field => {
			let fieldOptions = rootGetters[`${getters.editSource.option}/itemOptions`](field.options)
			fieldOptions.forEach(option => {
				if (option.children_ids.length > 0) {
					option.children_ids.forEach(child_id => {
						const child = _.cloneDeep(rootGetters[`${getters.editSource.option}/get`](child_id))
						options.push(child)
					})
				}
				options.push(_.cloneDeep(option))
			})
			fields.push(_.cloneDeep(field))
		})
		let data = {
			segment: {
				...segment
			},
			fields: fields,
			options: options,
			index: index
		}	
		commit('saveLastRemoved', data)
	},
	/**
	 * Return lastly removed segment and it's fields and options 
	 */
	returnLastlyRemoved ({ state, getters, commit, dispatch }) {
		commit('restoreSegment')	
		dispatch(`${getters.editSource.field}/restoreFields`, state.lastlyRemoved.fields, { root: true })
		dispatch(`${getters.editSource.option}/restoreOptions`, state.lastlyRemoved.options, { root: true })
		dispatch('validateSegments')
	},
	/**
	 * Validate all survey's/template's segments 
	 */
	validateSegments ({ state, commit }, languages) {
		// Languages are passed as extran parameter
		// if languages are not given fallback
		if(!Array.isArray(languages) || !languages.length) {
			languages = ['fi', 'en'];
		}

		// Remove languages outside scope
		Object.keys(state.notValid).forEach(locale => {
			if(!languages.includes(locale)) {
				commit('removePropertyFromNotValid', locale)
			}
		})

		Object.values(state.all).forEach(segment => {
			languages.forEach(language => {
				if(!segment.translations[language] || segment.translations[language].title === '') {
					commit('addToNotValid', {
						segment: segment.id,
						locale: language
					})
				}
			})
		})
	},
}
