export default {
	data: state => state.data,
	hasInvalidValues (state, getters) {
		const hasInvalidSegments = getters['segment/hasInvalidValues']
		const hasInvalidFields = getters['field/hasInvalidValues']
		const hasInvalidOptions = getters['option/hasInvalidValues']
		return hasInvalidSegments || hasInvalidFields || hasInvalidOptions
	},
	locale: state => state.locale
}
