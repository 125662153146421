import Vue from 'vue'

export default {
	/**
	 * set state rule groups
	 * @param {Object} groups 
	 */
	setRuleGroups(state, groups) {
		state.all = groups 
	},
	/**
	 * Add a new group for jump rules
	 * @param {Object} jump_rule_group
	 */
	add(state, jump_rule_group) {
		state.all = {
			...state.all,
			[jump_rule_group.id]: jump_rule_group
		}	
	},
	/**
	 * Remove jump_rule_group 
	 * @param {String} jump_rule_group_id 
	 */
	remove(state, jump_rule_group_id) {
		if (!state.all[jump_rule_group_id].new) {
			state.removed.push(jump_rule_group_id)
		}
		Vue.delete(state.all, jump_rule_group_id)
	},
	/**
	 * Add a new rule to given  group
	 * @param {Object} payload 
	 */
	addRule(state, payload) {
		state.all[payload.ruleGroup].jump_rules.push(payload.rule)	
	},
	/**
	 * Remove rule from the group
	 * @param {Object} rule 
	 */
	removeRule(state, rule) {
		const index = state.all[rule.jump_rule_group_id].jump_rules.indexOf(rule.id)	
		if (index !== -1) {
			state.all[rule.jump_rule_group_id].jump_rules.splice(index, 1)
		}
	},
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
}
