import mixinMutations from '@/store/mixins/segment/mutations'
import mixinActions from '@/store/mixins/segment/actions'
import mixinGetters from '@/store/mixins/segment/getters'

export default {
	namespaced: true,
	state: {
		all: {},
		segmentList: [],
		removed: [],
		lastlyRemoved: {},
		editing: null,
		gapActions: null,
		notValid: {
			fi: [],
			en: []
		}
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.template
		}
	},
  mutations: mixinMutations,
	actions: {
		...mixinActions
	} 
}
