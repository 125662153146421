import mutations from './mutations'
import actions from './actions'
import surveyViewer from './survey-viewer'
import templateViewer from './template-viewer'

export default {
	namespaced: true,
	state: {
		data: {},
		jumpsData: {},
		template_option: 'template',
		chosen_template: {},
		chosen_survey: {},
		fetching_viewer_data: false,
	},
	getters: {
		templateOption: state => state.template_option,
		shownSurvey: (state) => {
			if (state.template_option === 'template') {
				return state.chosen_template
			} else if (state.template_option === 'survey') {
				return state.chosen_survey
			} else {
				return {}
			}
		},
		chosenTemplate: state => state.chosen_template,
		fetchingViewerData: state => state.fetching_viewer_data
	},
	mutations: mutations,
	actions: actions,
	modules: {
		surveyViewer: surveyViewer,
		templateViewer: templateViewer
	}
}
