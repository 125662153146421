<template>
  <v-overlay :value="show" z-index="900">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BaseLoader',
  computed: {
    ...mapGetters('loader', {
      show: 'show'
    })
  }
}
</script>
