import { normalize, schema} from 'normalizr'
import { v4 as uuidv4 } from 'uuid';

const optionProcessStrategy = (value, parent, key) => {
	let translations = {}
	value.translations.forEach(translation => {
		translations = {
			...translations,
			[translation.locale]: {
				label: translation.label,
				value_question: translation.value_question
			}
		}	
	})
	return {
		...value,
		field: parent.id,
		translations: translations,
		new: false
	}
}

const fieldProcessStrategy = (value, parent, key) => {
	let translations = {}
	value.translations.forEach(translation => {
		translations = {
			...translations,
			[translation.locale]: {
				label: translation.label
			}
		}	
	})
	return {
		...value,
		segment: parent.id,
		translations: translations,
		new: false,
	}
}

const segmentProcessStrategy = (value, parent, key) => {
	let translations = {}
	value.translations.forEach(translation => {
		translations = {
			...translations,
			[translation.locale]: {
				title: translation.title
			}
		}	
	})
	return {
		...value,
		translations: translations,
		forced: false,
		new: false,
	}
}

const forcedSegmentProcessStrategy = (value, parent, key) => {
	let translations = {}
	value.translations.forEach(translation => {
		translations = {
			...translations,
			[translation.locale]: {
				title: translation.title
			}
		}	
	})
	return {
		...value,
		translations: translations,
		forced: true,
		new: false,
	}
}

const ruleProcessStrategy = (value, parent, key) => {
	let obj =  {
		...value,
		jump: parent.id,
		options: value.options_list,
		new: false,
	}
	delete obj.options_list
	return obj
}

const jumpRuleGroupProcessStrategy = (value, parent, key) => {
	return {
		...value,
		new: false,
	}
}

const jumpProcessStrategy = (value, parent, key) => {
	return {
		...value,
		new: false,
		jumpToEnd: value.to_segment_id === null ? true : false
	}
}

const categoryProcessStrategy = (value, parent, key) => {
	return {
		...value,
		new: false
	}
}

const optionSchema = new schema.Entity(
	'options',
	{
		field: fieldSchema,
	},
	{
		processStrategy: optionProcessStrategy
	}
) 

const fieldSchema = new schema.Entity(
	'fields',
	{
		options: [optionSchema]
	},
	{
		processStrategy: fieldProcessStrategy
	}
)


const segmentSchema = new schema.Entity(
	'segments',
	{
		fields: [fieldSchema],
	},
	{
		processStrategy: segmentProcessStrategy
	}
)

const categorySchema = new schema.Entity(
	'categories',
	{
		segments: [commonSegmentSchema]
	},
	{
		processStrategy: categoryProcessStrategy
	}
)

const commonSegmentSchema = new schema.Entity(
	'segments',
	{
		fields: [fieldSchema],
		categories: [categorySchema]
	},
	{
		processStrategy: segmentProcessStrategy
	}
)

const forcedSegmentSchema = new schema.Entity(
	'segments',
	{
		fields: [fieldSchema],
	},
	{
		processStrategy: forcedSegmentProcessStrategy
	}
)

const segmentListSchema = [segmentSchema]
const commonSegmentListSchema = [commonSegmentSchema]
const forcedSegmentListSchema = [forcedSegmentSchema]

const ruleSchema = new schema.Entity(
	'jump_rules',
	{},
	{
		processStrategy: ruleProcessStrategy
	}
)

const jumpRuleGroupSchema = new schema.Entity(
	'jump_rule_groups',
	{
		jump_rules: [ruleSchema]
	},
	{
		processStrategy: jumpRuleGroupProcessStrategy
	}
)

const jumpSchema = new schema.Entity(
	'jumps',
	{
		jump_rule_groups: [jumpRuleGroupSchema]	
	},
	{
		processStrategy: jumpProcessStrategy
	}
)

const jumpListSchema = [jumpSchema]

export default {
	getSegmentEntities (segments) {
		const {entities} = normalize(segments, segmentListSchema)
		return entities
	},
	getCommonSegmentEntities (segments) {
		const {entities} = normalize(segments, commonSegmentListSchema)
		return entities
	},
	getForcedSegmentEntities (segments) {
		const {entities} = normalize(segments, forcedSegmentListSchema)
		return entities
	},
	getJumpEntities (jumps) {
		const {entities} = normalize(jumps, jumpListSchema)
		return entities
	}
}
