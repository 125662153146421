import mixinGetters from '@/store/mixins/option/getters'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: []
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.commonSegment
		}
	},
	mutations: {
		setOptions(state, options) {
			state.all = options
		}
	},
}
