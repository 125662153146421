import Http from '../Http'

export default {
	async all () {
		try {
			const response = await Http.get('api/subscriber/categories') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new resource
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/subscriber/categories', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update resource
	 *
	 * @param {String} id id of the resource
	 * @param {Object} data updatedata 
	 * @return {Object}
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/categories/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen category 
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/subscriber/categories/${id}`)
		} catch (err) {
			throw err
		}
	}
}
