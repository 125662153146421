import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fi from '@/i18n/fi'
import en from '@/i18n/en'

Vue.use(VueI18n)

export default new VueI18n({
	locale: 'fi',
	messages: {
		fi: fi,
		en: en
	}
})
