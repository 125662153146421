export default {
	/**
	 * Set notifications
	 * @param {Array} notifications 
	 */
	setNotifications(state, notifications) {
		state.current = notifications
	},
	/**
	 * Toggle notifications
	 * @param {Boolean} value 
	 */
	toggleNotifications(state, value) {
	 state.showing = value
	},
	/**
	 * set allow timeout
	 * @param {Boolean} value 
	 */
	setAllowTimeoute(state, value) {
		state.allowTimeout = value	
	},
}
