import CategoryApi from '@/services/api/CategoryApi'

export default {
	/**
	 * Get all categories 
	 */
	async all ({ commit }) {
		try {
			const categories = await CategoryApi.all()
			let all = {}
			categories.forEach(category => {
				all = {
					...all,
					[category.id]: category
				}
			})
			commit('setAll', all)
		}	catch (err) {
			throw console.log(err) 
		}	
	},
	/**
	 * Store new category 
	 */
	async store ({ state, commit, dispatch }) {
		try {
			let data = {
				...state.creating,
				hex_color: `#${Math.floor(Math.random()*16777215).toString(16)}`
			}
			const category = await CategoryApi.store(data)
			dispatch('all')
		}	catch (err) {
			throw console.log(err) 
		}	
	},
	/**
	 * Edit category 
	 */
	async update ({ state, commit, dispatch }) {
		try {
			let data = {
				...state.editing,
			}
			const category = await CategoryApi.update(data.id, data)
			dispatch('all')
		}	catch (err) {
			throw console.log(err) 
		}	
	},
	/**
	 * Delete category 
	 * @param {String} id
	 */
	async remove ({ commit, dispatch }, id) {
		try { 
			await CategoryApi.delete(id)
			dispatch('all')
		} catch (err) {
			console.log(err)
		}	
	},
}
