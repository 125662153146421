import Http from '../Http'

export default {
	async all () {
		try {
			const response = await Http.get('api/subscriber/survey_targets') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey_target	 
	 * @param {String} id id of the target 
	 * @param {Object} data 
	 * @return {Object}
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/survey_targets/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen survey target
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			const response = await Http.delete(`api/subscriber/survey_targets/${id}`)
			return response.data
		} catch (err) {
			throw err
		}
	}
}
