import CommonSegmentApi from "@/services/api/CommonSegmentApi";
import Normalizer from "@/services/Normalizer";

export default {
  /**
   * Get user's common segments
   */
  async all({ dispatch, commit }) {
    try {
      const responseSegments = await CommonSegmentApi.all();
      if (responseSegments.length) {
        const { segments, fields, options, categories } =
          Normalizer.getCommonSegmentEntities(responseSegments);
        commit("setSegments", segments);
        commit("field/setFields", fields);
        commit("option/setOptions", options);
      } else {
        commit("setSegments", {});
      }
    } catch (err) {}
  },
  /**
   * Remove segment and segment's fields
   * @param {String} segment_id
   */
  async remove({ state, commit, dispatch }, segment_id) {
    try {
      const segment = await CommonSegmentApi.delete(segment_id);
      return segment;
    } catch (err) {
      throw err;
    }
  },
  /**
   * Set segment to be edited
   * @param {String} segment_id
   */
  setEditingSegment({ state, getters, commit, dispatch }, segment_id) {
    let segment = state.all[segment_id];
    commit("editing/setEditing", segment);
    commit("editing/clearNotValid");
    let fields = getters["field/segmentFieldsObject"](segment.fields);
    dispatch("editing/field/setFields", fields);
    Object.values(fields).forEach((field) => {
      let options = getters["option/itemOptionsObject"](field.options);
      dispatch("editing/option/setOptions", options);
    });
  },
  /**
   * description
   * @param {}
   */
  async store({ state, commit }) {
    const segment = await CommonSegmentApi.store(state.creating);
    return segment;
  },
  /**
   * Create copy from the existing segment
   * @param {String} segment_id original segment id
   */
  async createCopy({ state, getters, commit, dispatch }, segment_id) {
    return await CommonSegmentApi.copy(segment_id);
  },
};
