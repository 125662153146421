import Vue from 'vue'

export default {
	/**
	 * Set preview segments with fields and options
	 * @param {Array} segments 
	 */
	setPreviewSegments(state, segments) {
		state.segments = segments
	},
	/**
	 * Set preview jumps with groups and rules
	 * @param {Array} jumps 
	 */
	setPreviewJumps(state, jumps) {
		state.jumps = jumps
	},
	/**
	 * Set active segment
	 * @param {Object} segment 
	 */
	setActiveSegment(state, segment) {
		state.activeSegment = segment	
	},
	/**
	 * Set active page
	 * @param {String} page 
	 */
	setActivePage(state, page) {
		state.activePage = page
	},
	/**
	 * Add new answer to answers list
	 * @param {Object} answer 
	 */
	addAnswer(state, answer) {
		state.answers = {
			...state.answers,
			[answer.field_id]: answer
		}
	},
	/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.answers[payload.field_id].value = payload.value
	},
	/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	 editPassages(state, payload) {
		state.answers[payload.field_id].passages = payload.passages
	},
		/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	editIsDone(state, payload) {
			state.answers[payload.field_id].is_done = payload.value
		},
	/**
	 * Edit answer's option
	 * @param {Object} payload 
	 */
	editOption(state, payload) {
		state.answers[payload.field_id].option_id = payload.value	
	},
	/**
	 * Edit answer's options
	 * @param {Object} payload 
	 */
	editOptionsList(state, payload) {
		state.answers[payload.field_id].options = payload.value
	},
	/**
	 * Set parent option to answer 
	 * @param {Object} payload 
	 */
	setParent(state, payload) {
		state.answers[payload.field_id].parent_option_id = payload.value	
	},
	/**
	 * Clear answer's option
	 * @param {String} field_id 
	 */
	clearOption(state, field_id) {
		state.answers[field_id].option_id = null
	},
	/**
	 * Clear answer's options
	 * @param {String} field_id 
	 */
	clearOptions(state, field_id) {
		state.answers[field_id].options = []	
		state.answers[field_id].option_id = null
		state.answers[field_id].parent_option_id = null	
	},
	/**
	 * Reset answers
	 */
	clearAnswers(state) {
		state.answers = {}
	},
	/**
	 * Clear invalid fields list
	 */
	clearInvalid(state) {
		state.invalidFields = []	
	},
	/**
	 * Add to history
	 * @param {Object} segment
	 */
	addToHistory(state, segment) {
		state.history.push(segment)	
	},
	/**
	 * Remove last segment from history
	 * @param {}  payload 
	 */
	removeLastFromHistory(state,  payload) {
		state.history.pop()	
	},
	/**
	 * Clear segment history
	 */
	clearHistory(state) {
		state.history = []	
	},
	/**
	 * Add field to invalid list
	 * @param {String} field_id 
	 */
	addFieldToInvalid(state, field_id) {
		const index = state.invalidFields.indexOf(field_id)		
		if (index === -1) {
			state.invalidFields.push(field_id)	
		}
	},
	/**
	 * Remove field from invalid list
	 * @param {String} field_id 
	 */
	removeFromInvalid(state, field_id) {
		const index = state.invalidFields.indexOf(field_id)
		if (index > -1) {
			state.invalidFields.splice(index, 1)
		}
	},
	/**
	 * Set oldNextSegment. Is used when we want to remove
	 * answers between segment. This happens if user goes back
	 * and changes answers and we have a new jump route
	 * @param {Object} segment 
	 */
	setOldNextSegment(state, segment) {
		state.oldNextSegment = _.cloneDeep(segment)	
	},
	/**
	 * Reset old next segment after 'next'-action
	 */
	resetOldNextSegment(state, segment) {
		state.oldNextSegment = null	
	},
	/**
	 * Set given answers as axtra
	 * @param {Array} answers answer ids 
	 */
	setAnswersAsExtra(state,  answers) {
		answers.forEach(answer => {
			if (state.answers.hasOwnProperty(answer)) {
				Vue.set(state.answers[answer], 'is_extra', true)
			}
		})
	},
	/**
	 * description
	 * @param {}  payload 
	 */
	setAnswerAsNotExtra(state, payload) {
		const fields = state.activeSegment.fields
		fields.forEach(field => {
			if (state.answers.hasOwnProperty(field.id)) {
				Vue.set(state.answers[field.id], 'is_extra', false)
			}
		})
	},
}
