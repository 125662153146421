import Http from '../Http'

export default {
	async all () {
		try {
			const response = await Http.get('api/subscriber/survey_templates') 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get survey template by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/subscriber/survey_templates/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new survey template
	 *
	 * @param {Object} data
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/subscriber/survey_templates', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update template's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/survey_templates/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update template's segments, fields and options
	 *
	 * @param {String} id id of the template 
	 * @param {Object} data segments, fields and options 
	 * @return {Object}
	 */
	async updateParts (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/survey_templates/${id}/survey-parts`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen survey template
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/subscriber/survey_templates/${id}`)
		} catch (err) {
			throw err
		}
	}
}
