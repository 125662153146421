import Normalizer from '../../../../services/Normalizer'

export default {
	/**
	 * Normalize survey's data and set datas to modules 
	 * @param {Object} survey
	 */
	setSurveyData ({ commit, dispatch }, data) {
		let survey = data
		let segments = {}, fields = {}, options = {}, jumps = {}, jump_rule_groups = {}, jump_rules = {}
		if (survey.segments.length) {
			const survey_segments = _.cloneDeep(survey.segments)
			const normalized_segments = Normalizer.getSegmentEntities(survey_segments)
			segments = normalized_segments.segments
			if (normalized_segments.hasOwnProperty('fields')) {
				fields = normalized_segments.fields
			}
			if (normalized_segments.hasOwnProperty('options')) {
				options = normalized_segments.options
			}
		} 
		if (survey.jumps.length) {
			const survey_jumps = _.cloneDeep(survey.jumps)
			const normalized_jumps = Normalizer.getJumpEntities(survey_jumps) 
			jumps = normalized_jumps.jumps
			if (normalized_jumps.hasOwnProperty('jump_rule_groups')) {
				jump_rule_groups = normalized_jumps.jump_rule_groups
			}
			if (normalized_jumps.hasOwnProperty('jump_rules')) {
				jump_rules = normalized_jumps.jump_rules
			}
		}
		delete survey.segments
		delete survey.jumps

		// Change locale to first
		if(data.languages && data.languages.length > 0) {
			let locale = data.languages[0]
			if(locale.locale) commit('changeLocale', locale.locale)
		}

		commit('setData', survey)
		dispatch('segment/setSegments', segments)
		dispatch('field/setFields', fields)
		dispatch('option/setOptions', options)
		dispatch('jump/setJumps', jumps)
		dispatch('jump/ruleGroup/setRuleGroups', jump_rule_groups)
		dispatch('jump/rule/setRules', jump_rules)
		if (Object.keys(segments).length == 0) {
			commit('segment/add', 0)
		}
	},
}
