<template>
  <v-breadcrumbs :items="breadcrumbs">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    routes: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * Return path objects for the breadcrumbs component 
     * @return {Array} 
     */
    breadcrumbs () {
      const items = []
      let current = this.$route
      items.push({
        to: current.path,
        text: current.meta.title,
        disabled: false 
      })
      let parents = []
      if (current.hasOwnProperty('meta') && current.meta.hasOwnProperty('parent')) {
       parents = this.findParents(current, parents) 
       parents.reverse()
      }
      return [...parents, ...items]
    }
  },
  methods: {
    /**
     * Find parent and grand parent of the current route
     *
     * @param {Object} route current route or parent route
     * @param {Array} parents currently found parents
     * @return {Array} 
     */
    findParents (route, parents) {
      const items = parents
      if (route.hasOwnProperty('meta') && route.meta.hasOwnProperty('parent')) {
        let parent = this.routes.find(item => {
          return item.name === route.meta.parent
        }) 
        if (typeof parent !== 'undefined') {
          items.push({
            to: parent.path,
            text: parent.meta.title,
            disabled: false,
            exact: true
          })
          this.findParents(parent, items)
          return items
        } else {
          return []
        }
      } else {
        return []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

