export default {
	/**
	 * set chosen target for the survey to be created
	 * @param {Object} chosenTarget 
	 */
	setChosenTarget(state, chosenTarget) {
		state.chosen_target = chosenTarget
	},
	/**
	 * set true if user is creating a new target while making survey
	 * @param {Boolean} isCreatingTarget 
	 */
	setIsCreatingTarget(state, isCreatingTarget) {
		state.is_creating_target = isCreatingTarget
	},
	/**
	 * Edit new target data
	 * @param {Object} payload 
	 */
	editNewTarget(state, payload) {
		state.new_target_data = { ...state.new_target_data, ...{[payload.key]: payload.value} }
	},
	/**
	 * Set template option for survey
	 * @param {String} option 
	 */
	setTemplateOption(state, option) {
		state.template_option = option
	},
	/**
	 * Set chosen template data
	 * @param {Object} surveyTemplate 
	 */
	setTemplate(state, surveyTemplate) {
		state.chosen_template = surveyTemplate		
	},
	/**
	 * set existing survey to be copied as template
	 * @param {Object} surveyCopy 
	 */
	setSurveyCopy(state, surveyCopy) {
		state.chosen_survey = surveyCopy
	},
	/**
	 * Edit survey data for storing information
	 * @param {Object}  payload 
	 */
	editNewSurvey(state, payload) {
		state.data = { ...state.data, ...payload }
	},
	/**
	 * Set if is fetching data for template or survey
	 * @param {Boolean} payload 
	 */
	setFetchingViewerData(state, payload) {
		state.fetching_viewer_data = payload	
	},
	/**
	 * Toggle dialog
	 * @param {Boolean} dialog	 
	 */
	toggleDialog(state, dialog) {
		state.dialog = dialog
	},
	/**
	 * Reset survey creating data
	 */
	reset(state) {
		Object.assign(state, {
			dialog: false,
			data: {},
			chosen_target: {},
			new_target_data: {
				type: 'resort'
			},
			is_creating_target: false,
			template_option: 'template',
			chosen_template: {},
			chosen_survey: {},
		})
	},
}
