export default {
	all: state => Object.keys(state.all).map(key => state.all[key]),
	/**
	 * Return jumps with nested jump groups 
	 * @return {Array} 
	 */
	withGroups (state, getters, rootState, rootGetters) {
		const source = getters.editSource
		const jumps = _.cloneDeep(getters.all)
		return jumps.map(jump => {
			const jumpGroups = jump.jump_rule_groups
			const nestedGroups = rootGetters[`${source.ruleGroup}/withRules`](jumpGroups)
			return {
				...jump,
				jump_rule_groups: nestedGroups,
			}
		})
	},
	segmentJumps: (state) => (segment) => {
		let arr = []
		Object.keys(state.all).forEach(jump_key => {
			if (state.all[jump_key].from_segment_id === segment) {
				arr.push(state.all[jump_key])
			}
		})
		return arr
	},
	hasInvalidValue: (state) => (jump_id) => state.notValid.includes(jump_id),
	notValid: state => state.notValid,
	hasInvalidValues (state, getters, rootState, rootGetters) {
		const editSource = getters['editSource']
		if (
			rootGetters[`${editSource.rule}/notValid`].length > 0
			|| getters.notValid.length > 0
		) {
			return true
		} else {
			return false
		}
	}
}
