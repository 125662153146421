import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/subscriber'
import {routes} from './routes'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
	return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
	});
}
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored

	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!store.getters['auth/authenticated']) {
			next({
				path: '/kirjaudu',
			})
		} else {
			next()
		}
	} else {
		next() // make sure to always call next()!
	}
  next()
}

router.beforeEach(waitForStorageToBeReady)

export default router;
