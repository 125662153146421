import { getHappeningJumps } from '@/lib/survey-filler-helpers'

export default {
	/**
	 * Active firs segmnet for survey preview 
	 * @param {} 
	 */
	activateFirstSegment ({ commit, getters, rootGetters }, ) {
		const segmentSource = getters['editSource'].segment
		const segment = rootGetters[`${segmentSource}/getByIndex`](0)	
		commit('setActiveSegment', segment)
	},
	/**
	 * description 
	 * @param {} 
	 */
	createAnswer ({ commit }, field_id) {
		let answer = {
			field_id: field_id,
			value: null,
			options: [],
			option_id: null,
			parent_option_id: null,
			is_extra: false,
			passages: [],
			is_done : false,
		}	
		commit('addAnswer', answer)
	},
	start ({ state, commit, dispatch, getters, rootGetters }) {
		dispatch('createPreviewData')
		commit('clearInvalid')
		commit('clearAnswers')
		const source = getters.editSource
		const nextSegment = state.segments[0]
		nextSegment.fields.forEach(field => {
			if (!state.answers.hasOwnProperty(field.id)) {
				dispatch('createAnswer', field.id)
			}
		})
		commit('setActiveSegment', nextSegment)
		commit('setActivePage', 'segment')
	},
	/**
	 * Get next segment by answers andjumps 
	 */
	getNextSegment ({ state, getters }, ) {
		//Get jumps given to current segment	
		const segmentJumps = getters['segmentJumps'](state.activeSegment.id)
		let nextSegment = getters.nextSegment

		if (segmentJumps.length > 0) {
			const happeningJumps = getHappeningJumps(segmentJumps, getters.answers)
			if (happeningJumps.length > 0) {
				let jump = happeningJumps[0]
				if (jump.to_segment_id === null) {
					nextSegment = null
				} else {
					nextSegment = getters.getSegment(jump.to_segment_id)
				}
			}		
		}
		return nextSegment
	},
	/**
	 * Set answer and change to next segment 
	 */
	async next ({ state, commit, dispatch, getters, rootGetters }) {
		const source = getters.editSource
		const activeSegment = getters.activeSegment
		if (state.invalidFields.length > 0) {
			return
		} else if (getters.isLastSegment) {
			commit('setActivePage', 'end')
		} else {
			const nextSegment = await dispatch('getNextSegment')
			if (nextSegment !== null) {
				nextSegment.fields.forEach(field => {
					if (!state.answers.hasOwnProperty(field.id)) {
						dispatch('createAnswer', field.id)
					}
				})
				commit('addToHistory', getters.activeSegment)
			} else {
				commit('setActivePage', 'end')
			}
			//Check if our route changes
			if (nextSegment === null) {
			//If nextSegment == null, route has changed because we can't return from null
				await dispatch('removeAnswersBetween', {
					start: activeSegment.sort_order,
					end: null 
				})
			} else if (
				state.oldNextSegment
				&& nextSegment.id !== state.oldNextSegment.id
			) {
			//We are you using different jump than before
				await dispatch('removeAnswersBetween', {
					start: activeSegment.sort_order,
					end: nextSegment.sort_order 
				})
			}
			//Set new active segment
			commit('setAnswerAsNotExtra')
			commit('setActiveSegment', nextSegment)
		}
	},
	/**
	 * description 
	 * @param {} 
	 */
	async previous ({ state, commit, dispatch, getters, rootGetters }, ) {
		if (state.activePage === 'terms') {
			commit('setActivePage', 'start')
		} else if (state.activePage === 'privacy') {
			commit('setActivePage', 'lottery')
		} else {
			if (state.history.length > 0) {
				const previousSegment = state.history[state.history.length - 1]
				if (previousSegment !== null) {
					commit('setActiveSegment', previousSegment)
				}
				commit('removeLastFromHistory')
				//Set oldNextSegment. We use this to compare if nextSegment changes.
				const nextSegment = await dispatch('getNextSegment')
				commit('setOldNextSegment', nextSegment)
			}
		}
	},
	/**
	 * Remove answers between nextSegment and activeSegment
	 * @example
	 * 1 -> 2 -> 3 -> 4 -> 5
	 * 2 <- 3 <- 4 <- 5
	 * 2 -> 5
	 * Remove 3 and 4 segment's answers
	 * 1 -> 2 -> [3 -> 4] -> 5
	 * @param {Object} payload
	 */
	removeAnswersBetween ({ commit, getters }, payload) {
		//Get segments which answers we want to remove
		const segments = getters.segments.filter(segment => {
			if (payload.end === null) {
				return segment.sort_order > payload.start
			} else {
				return segment.sort_order > payload.start && segment.sort_order < payload.end
			}
		})	
		let fields = []
		segments.forEach(segment => {
			const fieldIds = segment.fields.map(field => field.id)
			fields = [...fields, ...fieldIds]
		})
		commit('setAnswersAsExtra', fields)
	},
	/**
	 * Validate active segment's fields 
	 */
	validateSegmentFields ({ state, commit, getters, rootGetters, dispatch }) {
		const activeSegment = _.cloneDeep(state.activeSegment)	
		//const fields = getters['segmentFields'](activeSegment) 
		activeSegment.fields.forEach(field => {
			if (field.is_required) {
				const answer = getters['getAnswer'](field.id)
				dispatch('validateField', {
					field: field,
					answer: answer
				})
			}
		})
	},
	/**
	 * Validate field and add not invalid list if not valid 
	 * @param {Object} payload field and answer
	 */
	validateField ({ commit }, payload) {
		const field = payload.field
		const answer = payload.answer

		if (field.answer_type === 'select') {
			if (answer.option_id === null) {
				commit('addFieldToInvalid', field.id)
			}
		} else if (field.answer_type === 'multiselect') {
			if (answer.options.length === 0) {
				commit('addFieldToInvalid', field.id)
			}
		} else if (
			field.answer_type === 'input'
			|| field.answer_type === 'date'
			|| field.answer_type === 'year'
			|| field.answer_type === 'country'
			|| field.answer_type === 'city'
			|| field.answer_type === 'slider'
	) {
			if (answer.value === null || answer.value === '') {
				commit('addFieldToInvalid', field.id)
			}
		}
	},
	/**
	 * Reset answers, history and invalid list 
	 */
	reset ({ commit }) {
		commit('clearAnswers')
		commit('clearHistory')
		commit('clearInvalid')
	},
	/**
	 * Create data for preview to show 
	 * @param {} 
	 */
	createPreviewData ({ commit, getters, rootGetters }, ) {			
		let previewSegments = []
		let startSegments = []
		let endSegments = []
		let sectionJumps = []
		const source = getters.editSource
		if (source.type === 'survey') {
			const segments = rootGetters['survey/editing/segment/withFields']
			const surveyTarget = rootGetters['survey/editing/surveyTarget']
			if (surveyTarget.target_type_id) {
				startSegments = _.cloneDeep(rootGetters['surveySection/startSegments'](surveyTarget.target_type_id))
				endSegments = _.cloneDeep(rootGetters['surveySection/endSegments'](surveyTarget.target_type_id))
				//Get section jumps with target type
				sectionJumps = rootGetters['surveySection/getJumps'](surveyTarget.target_type_id)
			}	
			previewSegments = [...startSegments, ...segments, ...endSegments]

		} else {
			previewSegments = _.cloneDeep(rootGetters[`${source.segment}/withFields`])
		}
		const sorted = _.cloneDeep(previewSegments).map((segment, index) => {
			return {
				...segment,
				sort_order: index + 1
			}
		})
		const surveyJumps = rootGetters[`${source.jump}/withGroups`]
		const previewJumps = [...surveyJumps, ...sectionJumps]
		commit(`${source.preview}/setPreviewSegments`, sorted, { root: true })
		commit(`${source.preview}/setPreviewJumps`, previewJumps, { root: true })
	},
}
