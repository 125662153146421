import Vue from 'vue'

export default {
	setOptions(state, options) {
		state.all = options
	},
	/**
	 * Add a new option to the list
	 * @param {Object} option 
	 */
	add(state, option) {
		state.all = {
			...state.all,
			[option.id]: {
				...option,
			}
		}	
	},
	/**
	 * Add a new child option to the list
	 * @param {Object} option 
	 */
	addChild(state, option) {
		state.all[option.parent_id].children_ids.push(option.id)
	},
	/**
	 * Remove option 
	 * @param {String} option_id 
	 */
	remove(state, option_id) {
		if (!state.all[option_id].new) {
			state.removed.push(option_id)
		}
		Vue.delete(state.all, option_id)
	},
	/**
	 * description
	 * @param {} payload 
	 */
	removeChild(state, payload) {
		const index = state.all[payload.parent].children_ids.indexOf(payload.option)
		state.all[payload.parent].children_ids.splice(index, 1)	
	},
	/**
	 * Set options children options 
	 * @param {Object} payload
	 */
	setChildren (state, payload) {
		Vue.set(state.all[payload.option], 'children_ids', payload.children)
	},
	/**
	 * Set asks_value to false and remove all the locales' values
	 * @param {String} option_id 
	 */
	removeValueQuestion(state, option_id) {
		state.all[option_id] = {
			...state.all[option_id],
			asks_value: false 
		}	
		const locales = Object.keys(state.all[option_id].translations)
		let translations = state.all[option_id].translations
		locales.forEach(locale => {
			Vue.set(state.all[option_id].translations[locale], 'value_question', null)
		})
	},
	/**
	 * Edit option's value
	 * @param {Object} payload option's id, new value's key and value's value
	 */
	editValue(state, payload) {
		state.all[payload.option] = {
			...state.all[payload.option],
			[payload.key]: payload.value
		}	
		if (
			payload.key === 'label'
			&& payload.value !== ''
		) {
			if (state.notValid.includes(payload.option)) {
				let index = state.notValid.indexOf(payload.option)
				state.notValid.splice(index, 1)
			}
		}
	},
	/**
	 * Edit option's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue(state, payload) {
		if(!state.all[payload.option].translations.hasOwnProperty(payload.locale)) {
			state.all[payload.option].translations = {
				...state.all[payload.option].translations,
				[payload.locale]: {
					[payload.key]: payload.value
				}
			}
		}

		//state.all[payload.option] = {
			//...state.all[payload.option],
			//translations: {
				//...state.all[payload.option].translations,
				//[payload.locale]: {
					//...state.all[payload.option].translations[payload.locale]
				//}
			//} 		
		//}	
		Vue.set(state.all[payload.option].translations[payload.locale], payload.key, payload.value)
		//Remove from not valid is value isn't empty
		if (
			payload.key === 'label'
			&& payload.value !== '' 
		) {
			if (state.notValid.hasOwnProperty(payload.locale)
				&& state.notValid[payload.locale].includes(payload.option)) {
				let items = state.notValid[payload.locale]
				let index = items.indexOf(payload.option)
				items.splice(index, 1)
				Vue.set(state.notValid, payload.key, items)
			}
		}
	},
	/**
	 * Restore resently removed option 
	 * @param {Object} option 
	 */
	restoreOption(state, option) {
		state.all = {
			...state.all,
			[option.id]: {
				...option
			}
		}	
		const index = state.removed.indexOf(option.id)
		if (index > -1) {
			state.removed.splice(index, 1)
		}
	},	
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
	/**
	 * Add option_id to "not valid" list if it doesnt' pass validation
	 * @param {Object} payload 
	 */
	addToNotValid(state, payload) {
		if (!state.notValid.hasOwnProperty(payload.locale)) {
			state.notValid = {
				...state.notValid,
				[payload.locale]: []
			}
		}
		const index = state.notValid[payload.locale].indexOf(payload.option)
		if (index === -1) {
			state.notValid[payload.locale].push(payload.option)
		}
	},
	/**
	 * Remove item from "not valid" for all the locales list, making it "valid"
	 * @param {String} item_id 
	 */
	removeFromNotValid(state, item_id) {
		Object.keys(state.notValid).forEach(locale => {
			const index = state.notValid[locale].indexOf(item_id)	
			if (index !== -1) {
				state.notValid[locale].splice(index, 1)
			}
		})
	},
	removePropertyFromNotValid(state, key) {
		if (Object.keys(state.notValid).includes(key)) {
			const { [key]: removedItem, ...restProperties } = state.notValid
			state.notValid = restProperties;
		}
	},
	/**
	 * Clear "notValid" list 
	 */
	clearNotValidList(state) {
		state.notValid = {
			fi: [],
			en: []
		}
	},
}
