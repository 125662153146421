export default {
	date: 'Päivämäärä',
	freeText: 'Vapaa teksti',
	year: 'Vuosi',
	country: 'Maa',
	city: 'Kaupunki',
	name: 'Etu- ja sukunimi',
	email: 'Sähköposti',
	wayTo: 'Menomatka',
	wayBack: 'Paluumatka',
	oneWay: 'Yksisuuntainen',
	back: 'Takaisin',
}
