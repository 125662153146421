import auth from './auth'
import notification from './notification'
import loader from './loader'
import country from './country'
import translations from './translations'

export default {
	auth: auth,
	notification: notification,
	loader: loader,
	country: country,
	translations: translations,
}
