export default {
	/**
	 * Set true if is fetching survey data
	 * @param {Boolean} value 
	 */
	setLoading(state, value) {
		state.loading = value
	},
	/**
	 * Set base data to be edited
	 * @param {Object} data 
	 */
	setData(state, data) {
		state.data = data
	},	
	/**
	 * Edit survey's/template's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.data = {
			...state.data,
			[payload.key]: payload.value
		}
	},
	/**
	 * Edit translation value
	 * @param {Object} payload 
	 */
	editTranslationValue(state, payload) {
		state.data = {
			...state.data,
			translations: {
				...state.data.translations,
				[payload.locale]: {
					...state.data.translations[payload.locale],
					[payload.key]: payload.value
				}
			} 		
		}	
	},
	/**
	 * Change editing survey's/template's locale
	 * @param {String} value 
	 */
	changeLocale(state, value) {
		state.locale = value	
	},
}
