import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid';

const template = {
	is_common: false,
	is_public: false,
	title: '',
	fields: [],
	translations: {
		fi: {
			title: ''
		},
		en: {
			title: ''
		}
	}
}

export default {
	setSegments(state, segments) {
		const list = Object.values(segments)
		list.sort((a, b) => {
			return a.pivot.sort_order - b.pivot.sort_order
		})
		state.segmentList = list.map(segment => {
			return segment.id
		})
		let nonCommon = {}
		let common = {}
		list.forEach(segment => {
			if (!segment.is_common) {
				nonCommon = {
					...nonCommon,
					[segment.id]: segment
				}
			} else {
				common = {
					...common,
					[segment.id]: segment
				}
			}
		})
		state.all = nonCommon
		state.common = common
	},
	/**
	 * Set new segment list for segments order
	 * @param {Array} value 
	 */
	setSegmentsList(state, value) {
		state.segmentList = value
	},
	/**
	 * Set page that is being edited
	 * @param {String} id 
	 */
	setEditing(state, id) {
		state.editing = id
	},
	/**
	 * Set segment which gap actions should be open
	 * @param {String} segment_id 
	 */
	setGapActions(state, segment_id) {
		state.gapActions = segment_id	
	},
	/**
	 * Add a new segment
	 * @param {String} segment_id Add after given segment_id 
	 */
	add(state, segment_id) {
		const id = uuidv4()
		let segment = {
			[id]: {
				id: id,
				new: true,
				..._.cloneDeep(template)
			}
		}
		state.all = {
			...state.all,
			...segment
		}
		//Add to correct position in the segment list
		if (segment_id === null) {
			state.segmentList.splice(0, 0, id)
		} else {
			const index = state.segmentList.indexOf(segment_id)
			state.segmentList.splice(index + 1, 0, id)
		}
		state.editing = id
		state.gapActions = null
	},
	/**
	 * Add a copy segment
	 * @param {Object} payload 
	 */
	addCopy(state, payload) {
		state.all = {
			...state.all,
			[payload.copy.id]: {...payload.copy}
		}
		//Add to correct position in the segment list
		const index = state.segmentList.indexOf(payload.original.id)
		state.segmentList.splice(index + 1, 0, payload.copy.id)
	},
	/**
	 * Add common segment to the survey/template
	 * @param {Object} payload 
	 */
	addCommonSegment(state, payload) {
		const index = payload.afterSegment === null
			? null
			: state.segmentList.indexOf(payload.afterSegment) 
		state.common = {
			...state.common,
			[payload.commonSegment.id]: payload.commonSegment
		}
		if (index === null) {
			state.segmentList.splice(0, 0, payload.commonSegment.id)
		} else {
			state.segmentList.splice(index + 1, 0, payload.commonSegment.id)
		}
	},
	/**
	 * Remove common segment
	 * @param {String} segment_id 
	 */
	removeCommonSegment(state, segment_id) {
		Vue.delete(state.common, segment_id)
		state.segmentList.splice(state.segmentList.indexOf(segment_id), 1)
	},
	/**
	 * Remove segment from list 
	 * @param {String} segment_id 
	 */
	remove(state, segment_id) {
		if (!state.all[segment_id].new) {
			state.removed.push(segment_id)
		}
		Vue.delete(state.all, segment_id)
		state.segmentList.splice(state.segmentList.indexOf(segment_id), 1)
	},
	/**
	 * Edit segment's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.all[payload.segment] = {
			...state.all[payload.segment],
			[payload.key]: payload.value
		}	
		//if (
			//payload.key === 'title'
			//&& payload.value !== ''
		//) {
			//if (state.notValid.includes(payload.segment)) {
				//let index = state.notValid.indexOf(payload.segment)
				//state.notValid.splice(index, 1)
			//}
		//}
	},
	/**
	 * Edit segment's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue(state, payload) {
		if(!state.all[payload.segment].translations.hasOwnProperty(payload.locale)) {
			state.all[payload.segment].translations = {
				...state.all[payload.segment].translations,
				[payload.locale]: {},
			}
		}

		Vue.set(
			state.all[payload.segment].translations[payload.locale],
			payload.key,
			payload.value
		)
		if (
			payload.key === 'title'
			&& payload.value !== '' 
		) {
			if (state.notValid.hasOwnProperty(payload.locale) && state.notValid[payload.locale].includes(payload.segment)) {
				let items = state.notValid[payload.locale]
				let index = items.indexOf(payload.segment)
				items.splice(index, 1)
				Vue.set(state.notValid, payload.key, items)
			}
		}
	},
	/**
	 * Add a new field to given segment
	 * @param {Object} payload segment's id and new field object 
	 */
	addField(state, payload) {
		state.all[payload.segment].fields.push(payload.field)	
	},
	/**
	 * Remove field from segment
	 * @param {Object} field 
	 */
	removeField(state, field) {
		const index = state.all[field.segment].fields.indexOf(field.id)	
		state.all[field.segment].fields.splice(index, 1)
	},
	/**
	 * Move segment's fields' positions
	 * @param {Object} payload 
	 */
	moveField(state, payload) {
		state.all[payload.segment].fields = arrayMove(
			state.all[payload.segment].fields,
			payload.fromIndex,
			payload.toIndex
		)
	},	
	/**
	 * Save removed segment data for undo
	 * @param {Object} payload 
	 */
	saveLastRemoved(state, payload) {
		state.lastlyRemoved = payload	
	},
	/**
	 * Restore resently removed segment 
	 */
	restoreSegment(state) {
		let segment = {...state.lastlyRemoved.segment}
		state.all = {
			...state.all,
			[segment.id]: {
				...segment
			}
		}	
		const index = state.removed.indexOf(segment.id)
		if (index > -1) {
			state.removed.splice(index, 1)
		}
		state.segmentList.splice(state.lastlyRemoved.index, 1, segment.id)
	},	
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
	/**
	 * Add segment_id to "not valid" list if it doesnt' pass validation
	 * @param {Object} payload 
	 */
	addToNotValid(state, payload) {
		if (!state.notValid.hasOwnProperty(payload.locale)) {
			state.notValid = {
				...state.notValid,
				[payload.locale]: []
			}
		}
		const index = state.notValid[payload.locale].indexOf(payload.segment)
		if (index === -1) {
			state.notValid[payload.locale].push(payload.segment)
		}
	},
	/**
	 * Remove item from "not valid" list, making it "valid"
	 * @param {String} item_id 
	 */
	removeFromNotValid(state, item_id) {
		Object.keys(state.notValid).forEach(key => {
			state.notValid[key]
			let index = state.notValid[key].indexOf(item_id)
			if (index > -1) {
				state.notValid[key].splice(index, 1)
			} 
		})
	},
	removePropertyFromNotValid(state, key) {
		if (Object.keys(state.notValid).includes(key)) {
			const { [key]: removedItem, ...restProperties } = state.notValid
			state.notValid = restProperties;
		}
	},
	/**
	 * Clear "notValid" list 
	 */
	clearNotValidList(state) {
		state.notValid = {
			fi: [],
			en: []
		}
	},
}

const arrayMove = (arr, fromIndex, toIndex) => {
    if (toIndex >= arr.length) {
        let k = toIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return arr; 
}
