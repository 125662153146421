import mixinGetters from '@/store/mixins/survey/showing/getters'
import mixinMutations from '@/store/mixins/survey/showing/mutations'
import mixinActions from '@/store/mixins/survey/showing/actions'

export default {
	namespaced: true,
	state: {
		data: {},
		segments: {},
		fields: {},
		options: {},
		jumps: {},
		jumpRuleGroups: {},
		jumpRules: {},
	},
	getters: mixinGetters,
  mutations: mixinMutations,
  actions: mixinActions
}
