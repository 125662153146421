import Normalizer from '@/services/Normalizer'
import SurveySectionApi from '@/services/api/SurveySectionApi'

export default {
	/**
	 * Get user's survey sections 
	 * @param {} 
	 */
	async all ({ commit, dispatch }, ) {
		try {
			const sections = await SurveySectionApi.all()	
			dispatch('setJumpsData', _.cloneDeep(sections))
			commit('setSections', sections)
			commit('setSegments', sections)
		} catch (err) {
			throw err
		}
	},
	/**
	 * Normalize jumps' data 
	 * @param {Object} survey
	 */
	setJumpsData ({ commit, dispatch }, sections) {
		let frameJumps = [] 
		sections.forEach(section => {
			if (section.jumps.length > 0) {
				frameJumps = [...frameJumps, ...section.jumps]	
			}
		})
		let jumps = {}, jump_rule_groups, jump_rules = {}
		if (frameJumps.length) {
			const survey_jumps = _.cloneDeep(frameJumps)
			const normalized_jumps = Normalizer.getJumpEntities(frameJumps) 
			jumps = normalized_jumps.jumps
			if (normalized_jumps.hasOwnProperty('jump_rule_groups')) {
				jump_rule_groups = normalized_jumps.jump_rule_groups
			}
			if (normalized_jumps.hasOwnProperty('jump_rules')) {
				jump_rules = normalized_jumps.jump_rules
			}
		}
		commit('setJumps', jumps)
		commit('setJumpRuleGroups', jump_rule_groups)
		commit('setRules', jump_rules)
	},
}
