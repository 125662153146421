<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    app
    :class="[
    {secondary: userType === 'subscriber'},
    {blue: userType === 'admin'}
    ]"
  >
    <v-img 
      height="100"
      contain
      src="@/assets/salmi_logo.png"
      class="my-6"
    ></v-img>
    <v-divider light></v-divider>
    <v-list 
      dense
      class="mt-8"
    >
      <v-list-item
        v-for="item in navigationItems"
        :key="item.name"
        link
        @click="goTo(item.path)"
      >
        <v-list-item-icon>
          <v-icon color="white">{{ item.meta.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">{{ item.meta.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="signOut">
        <v-list-item-icon>
          <v-icon class="white--text">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="white--text">
            Kirjaudu ulos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>

export default {
  name: 'Navigation',
  props: {
    navigationItems: {
      type: Array,
      required: true
    },
    userType: {
      type: String,
      default: 'subscriber'
    }
  },
  data () {
    return {
      drawer: false,
      mini: false,
    }
  },
  methods: {
    goTo (path) {
      this.$router.push({path: path})
    },
    async signOut () {
      try {
        await this.$store.dispatch('auth/signOut')
      } finally {
        this.$router.replace( {path: '/kirjaudu'} )
        const notifications = [{
          type: 'success',
          text: `Kirjauduttu ulos`
        }]
        this.$store.dispatch('notification/showNotifications', notifications)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

