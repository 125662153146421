import TargetTypeApi from '@/services/api/TargetTypeApi'

export default {
	namespaced: true,
	state: {
		all: []
	},
	getters: {
		all: state => state.all
  },
  mutations: {
		/**
		 * Set all types
		 * @param {Array} types 
		 */
		setAll(state, types) {
			state.all = types
		},
  },
  actions: {
		/**
		 * Get all target types 
		 * @param {} 
		 */
		async getTypes ({ commit }, ) {
			try {
				const types = await TargetTypeApi.all()
				commit('setAll', types)
			} catch (err) {
				throw err
			}		
		},
	}
}
