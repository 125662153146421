import SurveyTargetApi from '@/services/api/SurveyTargetApi'

export default {
	namespaced: true,
	state: {
		data: null 
	},
	getters: {
		data: state => state.data
  },
  mutations: {
		/**
		 * Set survey target data for edit
		 * @param {Object} payload 
		 */
		setData(state, payload) {
			state.data = payload
		},
		/**
		 * Edit target's value
		 * @param {Object} payload 
		 */
		editValue(state, payload) {
			state.data = {
				...state.data,
				[payload.key]: payload.value
			}	
		},
  },
  actions: {
		/**
		 * Update target data 
		 */
		async update ({ state, commit }, ) {
			try {
				const target = await SurveyTargetApi.update(state.data.id, state.data)	
				return target
			} catch (err) {
				throw err
			}
		},
	}
}
