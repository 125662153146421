export default {
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  endpointTitle: 'Was this your endpoint?',
  totalEndpointTitle: 'Was this your destination endpoint?',
  sameWayTitle: 'Did you return the same route?',
  selectMotivePower: 'Select motive power:',
  selectTransport: 'With which did you travel?',
  oneWay: 'One way',
}
