import mixinMutations from '@/store/mixins/survey/editing/mutations'
import mixinActions from '@/store/mixins/survey/editing/actions'
import mixinGetters from '@/store/mixins/survey/editing/getters'
import actions from './actions'
import segment from './segment'
import field from './field'
import option from './option'
import jump from './jump'
import preview from './preview'

export default {
	namespaced: true,
	state: {
		data: {},
		page_index: null,
		gap_open_index: null,
		focus_title: false,
		loading: false,
		locale: 'fi'
	},
	getters: {
		showing: state => state.data,
		loading: state => state.loading,
		...mixinGetters
  },
	mutations: mixinMutations,
	actions: {
		...mixinActions,
		...actions
	},
	modules: {
		segment: segment,
		field: field, 
		option: option,
		jump: jump,
		preview: preview
	}
}

