export default {
	all: state => state.all,
	get: (state) => (id) => state.all[id],
	invalidValues: state => state.notValid,
	hasInvalidValue: (state) => (locale, option_id) => {
		if(!state.notValid.hasOwnProperty(locale)) return false
		return state.notValid[locale].includes(option_id)
	},
	hasInvalidValues (state) {
		let hasInvalidValues = false
		Object.values(state.notValid).forEach(locale => {
			if (locale.length > 0) hasInvalidValues = true
		})
		return hasInvalidValues
	},
	itemOptions: (state) => (itemOptions) => {
		let options = itemOptions.map(id => state.all[id])
		return options.sort((a,b) => a.sort_order - b.sort_order)
	},
	itemOptionsPreview: (state, getters) => itemOptions => {
		let options = getters.itemOptions(itemOptions)
		return options.map(option => {
			const translations = Object.keys(option.translations).map(locale => {
				return {
					..._.cloneDeep(option.translations[locale]),
					locale: locale
				}
			})
			return {
				...option,
				translations: translations
			}
		})
	},
	itemOptionsObject: (state) => (itemOptions) => {
		let options = {}
		itemOptions.forEach(id => {
			options = {
				...options,
				[id]: state.all[id]
			}
		})
		return options
	},
	/**
	 * Sort by option's sort order and options' children sort order
	 * Used for jumps's list
	 *
	 * @return {Array} 
	 */
	sortedItemOptions: (state) => (itemOptions) => {
		let list = []
		let options = itemOptions.map(id => state.all[id])
		let parents = options.filter(option => option.parent_id === null)
		let sorted = parents.sort((a,b) => a.sort_order - b.sort_order)
		sorted.forEach(item => {
			list.push(item)
			if (item.children_ids.length) {
				let children = item.children_ids.map(id => state.all[id])
				let sorted_children = children.sort((a,b) => a.sort_order - b.sort_order)
				sorted_children.forEach(child => list.push({...child, parentOrder: item.sort_order}))
			}
		})
		return list	
	},
	parent: (state) => (child) => {
		return state.all[child.parent_id]
	},
	notValidFieldOptions: (state, getters) => (fieldOptions, locale) => {
		let options = fieldOptions
		fieldOptions.forEach(option_id => {
			if (state.all[option_id].children_ids.length > 0) {
				options = [...options, ...state.all[option_id].children_ids]
			}
		})
		if(!state.notValid.hasOwnProperty(locale)) return []
		return state.notValid[locale].filter(option_id => {
			return options.includes(option_id)
		})
	},
}
