export default {
	/**
	 * description
	 * @param {Array} segments 
	 */
	setSegments(state, segments) {
		state.all = segments	
	},
	/**
	 * Edit segment's store value
	 * @param {Object} payload 
	 */
	editCreatingValue(state, payload) {
		state.creating = {
			...state.creating,
			[payload.key]: payload.value
		}
	},
}
