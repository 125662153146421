import mixinMutations from '@/store/mixins/language/mutations'
import mixinActions from '@/store/mixins/language/actions'

export default {
	namespaced: true,
	state: {
		all: {},
	},
	getters: {
		all: state => Object.keys(state.all).map(key => state.all[key]),
  },
	mutations: {
		...mixinMutations
	},
	actions: {
		...mixinActions
	} 
}
