import Vue from 'vue'

export default {
	/**
	 * Edit templates value for creation
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		Vue.set(state.data, payload.key, payload.value)
	},
	/**
	 * Set template option for survey
	 * @param {String} option 
	 */
	setTemplateOption(state, option) {
		state.template_option = option
	},
	/**
	 * Set chosen template data
	 * @param {Object} surveyTemplate 
	 */
	setTemplate(state, surveyTemplate) {
		state.chosen_template = surveyTemplate		
	},
	/**
	 * set existing survey to be copied as template
	 * @param {Object} surveyCopy 
	 */
	setSurveyCopy(state, surveyCopy) {
		state.chosen_survey = surveyCopy
	},
	/**
	 * Set if is fetching data for template or survey
	 * @param {Boolean} payload 
	 */
	setFetchingViewerData(state, payload) {
		state.fetching_viewer_data = payload	
	},
	/**
	 * Reset template creating data
	 */
	reset(state) {
		Object.assign(state, {
			dialog: false,
			data: {},
			template_option: 'template',
			chosen_template: {},
			chosen_survey: {},
		})
	},
}
