import Http from '../Http'

export default {
	async all () {
		try {
			const response = await Http.get('api/subscriber/target_types') 
			return response.data
		} catch (err) {
			throw err
		}
	},
}
