import mixinGetters from '@/store/mixins/preview/getters'
import mixinActions from '@/store/mixins/preview/actions'
import mixinMutations from '@/store/mixins/preview/mutations'

export default {
	namespaced: true,
	state: {
		activePage: 'segment',
		segments: [],
		activeSegment: null,
		history: [],
		answers: {},
		invalidFields: []
	},
	getters: {
		...mixinGetters, 
		...{
			activePage: state => state.activePage,
			activeSegment: state => state.activeSegment,
			editSource (state, getters, rootState, rootGetters) {
				return rootGetters.editSources.template
			},
			segmentFields: (state, getters, rootState, rootGetters) => (segment) => {
				if (segment.forced) {
					return rootGetters[`surveySection/segmentFields`](segment.fields)
				} else if (segment.is_common) {
					return rootGetters['commonSegment/field/segmentFields'](segment.fields)
				} else {
					return rootGetters['surveyTemplate/editing/field/segmentFields'](segment.fields)
				}
			}
		}
  },
  mutations: {
		...mixinMutations
  },
  actions: {
		...mixinActions
	}
}
