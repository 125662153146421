export default {
	/**
	 * Set currently shown survey
	 * @param {Object} survey 
	 */
	show(state, survey) {
		survey.segments.sort((a,b) => {
			return a.pivot.sort_order - b.pivot.sort_order
		})
		state.showing = survey
	},
	/**
	 * set true if survey is being requeted and false after response
	 * @param {Boolean} isFetching 
	 */
	setFetching(state, isFetching) {
		state.is_fetching = isFetching
	},
	/**
	 * set surveys with targets
	 * @param {Array} targets 
	 */
	setAll(state, targets) {
		const surveys = []
		targets.forEach(target => {
		  target.surveys.forEach(survey => {
				surveys.push({
					id: survey.id,
					name: survey.name,
					target: target.name,
				})
		  })
		})
		state.all = surveys
	},
}
