import SurveyTemplateApi from '@/services//api/SurveyTemplateApi'

export default {
	/**
	 * Get survey templates 
	 */
	async all ({ commit }, templates) {
		try {
			const templates = await SurveyTemplateApi.all()
			commit('setAll', templates)
		} catch (err) {
			throw err
		}
	},
	/**
	 * Get requested survey template 
	 * @param {String} 
	 */
	async show ({ commit, dispatch }, id) {
		commit('setFetching', true)
		try {
			const template = await SurveyTemplateApi.show(id)
			dispatch('showing/setSurveyData', template)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setFetching', false)
		}
	},
	/**
	 * set true if survey is being requested and false after response 
	 * @param {Boolean} isFetching
	 */
	setFetching ({ commit }, isFetching) {
		commit('setFetching', isFetching)
	},
	/**
	 * Delete survey  template
	 * @param {String} id
	 */
	async remove ({ commit }, id) {
		try { 
			return await SurveyTemplateApi.delete(id)
		} catch (err) {
			console.log(err)
		}	
	},
}
