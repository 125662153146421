import { v4 as uuidv4 } from 'uuid';

export default {
	/**
	 * Set rules 
	 * @param {Object} rules 
	 */
	setRules ({ commit }, rules) {
		commit('setRules', rules)
		commit('clearRemoveList')
		commit('resetNotValid')
	},
	/**
	 * Add a new rule to the jump 
	 * @param {String} jump_rule_group_id 
	 */
	add ({ commit, getters }, jump_rule_group_id) {
		const rule = {
			id: uuidv4(),
			jump_rule_group_id: jump_rule_group_id,
			answer_segment_id: null,
			value: null,
			new: true,
			options: []
		}	
		commit('add', rule)
		//Add rule go rule group
		commit(`${getters.editSource.ruleGroup}/addRule`, {
			ruleGroup: jump_rule_group_id,
			rule: rule.id
		}, { root: true })
	},
	/**
	 * Remove rule 
	 * @param {String} rule_id
	 */
	remove ({ state, getters, commit, dispatch, rootGetters }, rule_id) {
		const rule = state.all[rule_id]
		commit(`${getters.editSource.ruleGroup}/removeRule`, rule, { root: true })
		commit('remove', rule_id)
		commit('removeFromNotValid', rule_id)
		//const group = rootGetters[`${getters.editSource.ruleGroup}/get`](rule.jump_rule_group_id)
		////Remove Jump rule group there are no rules left in the group
		//if (group.jump_rules.length == 0) {
			//dispatch(`${getters.editSource.ruleGroup}/remove`, group.id, { root: true })
		//}
	},
	/**
	 * Validate rule's values 
	 * @param {String} rule_id
	 */
	validateRule ({ state, commit }, rule_id) {
		const rule = state.all[rule_id]		
		if (
			rule.answer_segment_id === null
			|| rule.answer_field_id === null
			|| rule.options.length === 0
		) {
			commit('addToNotValid', rule_id)
		}
	},
}
