export default {
	ageConfirmation: 'Kyselyyn vastaaminen edellyttää yli 15-vuoden ikää. Jatkamalla vakuutat olevasi yli 15-vuotias.',
	chooseOne: 'Valitse yksi jatkaaksesi',
	chooseOneOrMany: 'Valitse yksi tai useampi',
	endText: 'Kysely on päättynyt. Kiitoksia vastaamisestasi!',
	startText: 'Auta meitä kehittämään palvelujamme vastaamalla kyselyyn.',
	welcome: 'Tervetuloa!',
	terms: 'Kysely on osa matkailutiedonkeruuta. Tietoa hyödynnetään matkailun tutkimuksessa sekä matkailun kehittämistyössä. Kyselyyn vastaaminen on vapaaehtoista. Kenenkään henkilökohtaisia tietoja ei tallenneta, luovuteta ulkopuolisille eikä käytetä markkinointitarkoituksiin.',
	lotteryTerms: '<h2>Salmi Platform Oy:n arvonnan tietosuojaseloste</h2><p><br />Ker&auml;&auml;mme arvontaamme osallistuvien henkil&ouml;tietoja arvonnan voittajan l&ouml;yt&auml;miseksi ja palkitsemiseksi.</p><p><br />Kysymme arvontaan osallistuvalta suostumuksen t&auml;m&auml;n henkil&ouml;tietojen ker&auml;&auml;miseen arvontaa varten. S&auml;ilyt&auml;mme arvontaan osallistuvien henkil&ouml;tietoja Salmi Platform Oy:n palveluntarjoajan (Hurja Solutions Oy, K&auml;sity&ouml;katu 43, 70100 Kuopio) tietokannassa ainoastaan arvontaan ja arvonnan voittajalle ilmoittamiseen saakka.</p><p><br />Henkil&ouml;tietoihin p&auml;&auml;sy on tarkkaan valvottua ja vahvasti suojattua. Arvontaan osallistuvien henkil&ouml;tietoja ei luovuteta kolmansille osapuolille eik&auml; k&auml;ytet&auml; mihink&auml;&auml;n muuhun kuin arvonnan toteuttamiseen.<br />Arvontaan henkil&ouml;tietonsa antanut henkil&ouml; voi pyyt&auml;&auml; henkil&ouml;tietonsa n&auml;ht&auml;ville ja pyyt&auml;&auml; tietojensa poistamista ennen arvonnan suorittamista, t&auml;ll&ouml;in h&auml;nen henkil&ouml;tietonsa poistetaan viipym&auml;tt&auml; tietokannasta.<br />Kaikkien arvontaan osallistuneiden henkil&ouml;tiedot poistetaan turvallisesti heti arvonnan j&auml;lkeen.</p>',
	'survey-completed': {
		thank_you_text: 'Kiitos kyselyyn vastaamisestasi! Haluamme palkita sinut antamalla sinulle mahdollisuuden osallistua arvontaamme.',
		input_info: 'Arvontaan osallistuaksesi jätä oheiset yhteystiedot. Käsittelemme kaikki kyselyn vastaukset anonyymisti ja siten yhteystietojasi ei yhdistetä edellä antamiisi vastauksiisi. Arvonnan suorittamisen jälkeen poistamme yhteystietosi rekisteristämme.'
	},
	selectLanguage: 'Valitse kieli',
	mapSearchText: 'Hae tästä!',
	mapInfoText: 'Millä kulkuneuvoilla saavuit kohteeseen? Täppää kartalta matkasi aloituspiste tai kirjoita hakuun lähtöpaikka. Etapit merkitään kulkuneuvoittain. Kartta kysyy etapin kohdalla kulkuneuvon.',
	tripDescriptionLabel:'Matkaketjun kuvaus',
	tripDescriptionInstruction:'Ohje matkaketjun kuvaukselle',
	mapMoveLabel:'Kartan liikuttaminen',
	mapMoveInstruction:'Ohje kartan liikuttamiselle',
	startPointLabel:'Matkan aloituspisteen valinta',
	startPointInstruction:'Ohje aloituspisteen valinnalle',
	markerMoveLabel:'Pisaran siirtäminen',
	markerMoveInstruction:'Ohje pisaran siirtämiselle',
	markerDeleteLabel:'Pisaran poistaminen',
	markerDeleteInstruction:'Ohje pisaran poistamiselle',
	tripEmissionText:'Matkasi päästöt yhteensä',
	showPassagesText:'Näytä etappipisteet',
	closePassagesText:'Sulje etappipisteet',
	tripFinishedText:'Matkasi yhteenveto',
	buttonDeleteText: 'Poista',
	buttonCancelText: 'Peruuta',
	deleteMapPointHeaderText: 'Haluatko varmasti poistaa matkaetapin?',
	deleteMapPointText: 'Oletko varma, että haluat poistaa matkaetapin ja sen seuraajat?'
}