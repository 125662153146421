import LanguageApi from '@/services/api/LanguageApi'

export default {
	/**
	 * Get all categories 
	 */
	async all ({ commit }) {
		try {
			const languages = await LanguageApi.all()
			let all = {}
			languages.forEach(language => {
				all = {
					...all,
					[language.locale]: language
				}
			})
			commit('setAll', all)
		}	catch (err) {
			throw console.log(err) 
		}	
	},
}
