export default {
	/**
	 * Set currently shown survey template 
	 * @param {Object} survey 
	 */
	show(state, template) {
		template.segments.sort((a,b) => {
			return a.pivot.sort_order - b.pivot.sort_order
		})
		state.showing = template 
	},
	/**
	 * set true if template is being requested and false after response
	 * @param {Boolean} isFetching 
	 */
	setFetching(state, isFetching) {
		state.is_fetching = isFetching
	},
	/**
	 * Set templates
	 * @param {Array} templates 
	 */
	setAll(state, templates) {
		state.all = templates
	},
}
