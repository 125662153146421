import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: {
			light: {
				primary: '#e47e67',
				secondary: '#2d333e',
				tertiary: '#8d94a1',
				bg: '#f4f5f7',
				success: '#2d333e',
				green: '#16a085',
				blue: '#0a3d62'
				//success: '#1dd1a1'
			}
		}
	},
});
