import buttons from './buttons/fi'
import labels from './labels/fi'
import messages from './messages/fi'
import texts from './texts/fi'
import dialogs from './dialogs/fi'

export default {
	buttons: buttons,
	labels: labels,
	messages: messages,
	texts: texts,
	dialogs: dialogs
}
