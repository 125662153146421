import TranslationApi from '@/services/api/TranslationApi'

export default {
	/**
	 * description 
	 * @param {} 
	 */
	async getTranslations ({ commit, dispatch }) {
			const translations = await TranslationApi.all()
			dispatch('setTranslations', translations)
			return translations
	},
	/**
	 * Normalize survey's data and set to state 
	 * @param {Object} survey
	 */
	setTranslations ({ commit, dispatch }, data) {
		//let segments = {}, fields = {}, options = {}
			const translations = _.cloneDeep(data)
			commit('setTranslations', translations)
	},
}
