export default {
	data: state => state.data,
	segments (state) {
		let segments = Object.keys(state.segments).map(key => state.segments[key])
		return segments.sort((a, b) => a.pivot.sort_order - b.pivot.sort_order)
	},
	segmentIdList: (state, getters) => getters.segments.map(segment => segment.id), 
	segmentByIndex: (state, getters) => segment_index => getters.segments[segment_index],
	getSegment: state => id => state.segments[id],
	nextSegment: (state, getters) => segment_id => {
		const currentSegmentIndex = getters.segmentIdList.indexOf(segment_id)
		if (
			currentSegmentIndex === -1
			|| currentSegmentIndex + 1 === getters.segmentIdList.length
		) {
			return null
		} else {
			const nextSegmentId = getters.segmentIdList[currentSegmentIndex + 1]
			return state.segments[nextSegmentId]
		}
	},
	isLastSegment: (state, getters) => segment_id =>  {
		const index = getters.segmentIdList.indexOf(segment_id)
		return index >= getters.segmentIdList.length - 1
	}, 
	getField: state => id => state.fields[id],
	getOption: state => id => state.options[id],
	fields: state => state.fields,
	options: state => state.options,
	jumps: state => state.jumps,
	segmentFields: (state) => (segmentFields) => {
		let fields = segmentFields.map(id => state.fields[id])
		return fields.sort((a, b) => a.pivot.sort_order - b.pivot.sort_order)
	},
	isLastField: (state, getters) => (segmentFields, field) => {
		const fields = getters.segmentFields(segmentFields) 
		return field.pivot.sort_order === fields.length
	},
	itemOptions: (state) => (itemOptions) => {
		let options = itemOptions.map(id => state.options[id])
		return options.sort((a,b) => a.sort_order - b.sort_order)
	},
	/**
	 * Sort by option's sort order and options' children sort order
	 *
	 * @return {Array} 
	 */
	sortedItemOptions: (state) => (itemOptions) => {
		let list = []
		let options = itemOptions.map(id => state.options[id])
		let parents = options.filter(option => option.parent_id === null)
		let sorted = parents.sort((a,b) => a.sort_order - b.sort_order)
		sorted.forEach(item => {
			list.push(item)
			if (item.children_ids.length) {
				let children = item.children_ids.map(id => state.options[id])
				let sorted_children = children.sort((a,b) => a.sort_order - b.sort_order)
				sorted_children.forEach(child => list.push({...child, parentOrder: item.sort_order}))
			}
		})
		return list	
	},
	segmentJumps: (state) => (segment) => {
		let arr = []
		Object.keys(state.jumps).forEach(jump_key => {
			if (state.jumps[jump_key].from_segment_id === segment) {
				arr.push(state.jumps[jump_key])
			}
		})
		return arr
	},
	jumpRuleGroups: (state) => (jumpRuleGroups) => {
		return jumpRuleGroups.map(id => state.jumpRuleGroups[id])
	}, 
	groupRules: (state) => (groupRules) => {
		return groupRules.map(id => state.jumpRules[id])
	} 
}
