export default {
	getAnswer: state => field_id => state.answers[field_id],
	activeSegment: state => state.activeSegment,
	segments: state => state.segments,
	isLastSegment (state, getters) {
		return getters.activeSegment.sort_order === state.segments.length
	},
	/**
	 * Get segment's that have a sort_order between given arguments
	 *
	 * @param {Number} start 
	 * @param {Number}  end
	 * @return {Array} 
	 */
	getSegmentsBetween: state => (start, end) => {
		return state.segments.filter(segment => {
			return segment.sort_order > start && segment.sort_order < end
		})	
	},
	nextSegment (state, getters) {
		if (!state.activeSegment) {
			return null
		}
		const next = getters.segments.find(segment => {
			return segment.sort_order === state.activeSegment.sort_order + 1
		})	
		return next ? next : null
	},
	/**
	 * Get jumps that have given segment_id as from_segment_id
	 *
	 * @param {String} 
	 * @return {Array} 
	 */
	segmentJumps: state => segment_id => {
		return state.jumps.filter(jump => jump.from_segment_id === segment_id)
	},
	/**
	 * Get segment from preview segments, which can have segments for section
	 *
	 * @param {String} segment_id 
	 * @return {Object|null} 
	 */
	getSegment: state => segment_id => {
		const foundSegment = state.segments.find(segment => segment.id === segment_id)
		return foundSegment ? foundSegment :  null
	},
	answers: state => state.answers,
	jumps: state => state.jumps,
	invalidFields: state => state.invalidFields,
}
