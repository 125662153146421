import { v4 as uuidv4 } from 'uuid';

export default {
	/**
	 * Set rule groups 
	 * @param {Object} groups 
	 */
	setRuleGroups ({ commit }, groups) {
		commit('setRuleGroups', groups)
		commit('clearRemoveList')
	},
	/**
	 * Add a new group for jump rules 
	 * @param {String} jump_id
	 */
	add({ getters, commit, dispatch }, jump_id) {
		const jump_rule_group = {
			id: uuidv4(),
			jump_id: jump_id,
			jump_rules: [],
			new: true
		}
		commit('add', jump_rule_group)
		commit(`${getters.editSource.jump}/addRuleGroup`, {
			jump: jump_id,
			group: jump_rule_group.id
		}, { root: true })
		dispatch(`${getters.editSource.rule}/add`, jump_rule_group.id, { root: true })
	},	
	/**
	 * Remove jump rule group and group's rules 
	 * @param {String} group_id 
	 */
	remove ({ state, getters, commit, dispatch }, group_id) {
		const group  = state.all[group_id]
		group.jump_rules.forEach(rule_id => {
			dispatch(`${getters.editSource.rule}/remove`, rule_id, { root: true })
		}) 
		commit(`${getters.editSource.jump}/removeRuleGroup`, group, { root: true })
		//If wasn't removed by rule remove
		if (state.all[group_id]) {
			commit('remove', group_id)	
		}
	},
}
