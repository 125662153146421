import mixinGetters from '@/store/mixins/field/getters'

const source = 'survey/editing'

export default {
	namespaced: true,
	state: {
		all: {},
		notValid: [],
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.commonSegment
		}
	},
	mutations: {
		setFields(state, fields) {
			state.all = fields
		},
	},
}
