import mixinGetters from  '@/store/mixins/survey/showing/getters'
import mixinMutations from '@/store/mixins/survey/showing/mutations'
import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		all: [],
		segments: [],
		jumps: {},
		jumpRuleGroups: {},
		jumpRules: {}
	},
	getters: {
		...mixinGetters,
		getByTargetType: (state) => (target_type_id) => {
			return state.all.filter(section => {
				if (section.target_types.length === 0) {
					return true
				} 
				let hasType = false
				section.target_types.forEach(type => {
					if (type.id === target_type_id) {
						hasType = true
					}	
				})
				return hasType
			})
		}, 
		/**
		 * Get section jumps by target type
		 *
		 * @param {String} target_type_id 
		 * @return {Array} 
		 */
		getJumps: (state, getters) => (target_type_id) => {
			let jumps = []
			const sections = getters.getByTargetType(target_type_id)
			if (sections.length > 0) {
				sections.forEach(section => {
					jumps = [...jumps, ..._.cloneDeep(section.jumps)]	
				})
			}
			return jumps
		},
		getByPosition: (state, getters) => (target_type_id, position) => {
			const typeSections = getters.getByTargetType(target_type_id)
			let sections = typeSections.filter(section => {
				return section.pivot.position === position
			})
			return sections.sort((a, b) => a.pivot.sort_order - b.pivot.sort_order)
		},
		/**
		 * Return segments whick section has position 'start'
		 *
		 * @param {String} target_type_id 
		 * @return {Array} 
		 */
		startSegments: (state, getters) => target_type_id => {
			let segments = []
			const sections = getters.getByPosition(target_type_id, 'start')
			sections.forEach(section => {
				const sectionSegments = _.cloneDeep(section.segments)
				const sorted = sectionSegments.sort((a, b) => {
					return a.pivot.sort_order - b.pivot.sort_order
				})
				segments = [...segments, ...sorted]
			})
			return segments
		},
		/**
		 * Return segments whick section has position 'end'
		 *
		 * @param {String} target_type_id 
		 * @return {Array} 
		 */
		endSegments: (state, getters) => target_type_id => {
			let segments = []
			const sections = getters.getByPosition(target_type_id, 'end')
			sections.forEach(section => {
				const sectionSegments = _.cloneDeep(section.segments)
				const sorted = sectionSegments.sort((a, b) => {
					return a.pivot.sort_order - b.pivot.sort_order
				})
				segments = [...segments, ...sorted]
			})
			return segments
		},
		segments: state => {
			let segments = Object.values(state.segments)
			return segments.sort((a, b) => a.pivot.sort_order - b.pivot.sort_order)
		},
		/**
		 * Get section segment by id
		 *
		 * @param {String} 
		 * @return {Object} 
		 */
		getSegment: state => segment_id => {
			const segment = state.segments.find(segment => segment.id === segment_id)
			return segment ? segment : null
		},
	},
	mutations: {
		/**
		 * Set sections
		 * @param {Array} sections 
		 */
		setSections(state, sections) {
			state.all = sections
		},
		...mixinMutations,
		...mutations
	},
	actions: {
		...actions
	} 
}
