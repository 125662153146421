export default {
	start: 'Start',
	next: 'Next',
	previous: 'Previous',
	continue: 'Continue',
	cancel: 'Cancel',
	participate: 'Participate',
	terms: 'Terms of use',
	privacy: 'Privacy'
}
