import SurveyTemplateApi from '@/services/api/SurveyTemplateApi'
import SurveyApi from '@/services/api/SurveyApi'

export default {
	/**
	 * Get requested survey template
	 * @param {String} 
	 */
	async showTemplate ({ dispatch, commit}, id) {
		try {
			commit('setFetchingViewerData', true)
			const surveyTemplate = await SurveyTemplateApi.show(id)
			dispatch('templateViewer/setSurveyData', surveyTemplate)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setFetchingViewerData', false)
		}
	},
	/**
	 * Get requested survey
	 * @param {String} 
	 */
	async showSurvey ({ dispatch, commit }, id) {
		try {
			commit('setFetchingViewerData', true)
			const survey = await SurveyApi.show(id)
			dispatch('surveyViewer/setSurveyData', survey)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setFetchingViewerData', false)
		}
	},
	/**
	 * Store a new survey template 
	 */
	async store ({ state, commit }) {
		commit('loader/toggleLoader', true, { root: true })
		let storeData = {
			new_survey_data: state.data,
			template_option: state.template_option
		}
		if (state.template_option === 'template') {
			storeData['chosen_template'] = state.templateViewer.data.id
		} else if (state.template_option === 'survey') {
			storeData['chosen_survey'] = state.surveyViewer.data.id
		}
		try {
			return await SurveyTemplateApi.store(storeData)
			commit('reset')
		} catch (err) {
			throw err
		}	finally {
			commit('loader/toggleLoader', false, { root: true })
		}
	},
}
