import SurveyApi from '@/services//api/SurveyApi'

export default {
	/**
	 * Set surveys with targets 
	 */
	setAll ({ commit  }, targets) {
		commit('setAll', targets)
	},
	/**
	 * Get requested survey
	 * @param {String} id 
	 */
	async show ({ commit, dispatch }, id) {
		commit('setFetching', true)
		try {
			const survey = await SurveyApi.show(id)
			dispatch('showing/setSurveyData', survey)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setFetching', false)
		}
	},
	/**
	 * Delete survey 
	 * @param {String} id
	 */
	async remove ({ commit }, id) {
		try { 
			return await SurveyApi.delete(id)
		} catch (err) {
			console.log(err)
		}	
	},
	/**
	 * Update survey's "is_published" value 
	 * @param {Object} payload
	 */
	async updateIsPublished ({ commit }, payload) {
		try {
			await SurveyApi.update(payload.id, {
				is_published: payload.is_published
			})
		} catch (err) {
			console.log(err)
		}		
	},
}
