import Http from '../Http'

export default {
	/**
	 * Get survey by id
	 *
	 * @param {String} id uuid
	 * @return {Object} 
	 */
	async show (id) {
		try {
			const response = await Http.get(`api/subscriber/surveys/${id}`) 
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Create a new survey and a new target (optional)
	 *
	 * @param {Object} 
	 * @return {Object} 
	 */
	async store (data) {
		try {
			const response = await Http.post('api/subscriber/surveys', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey's data
	 *
	 * @param {String} id
	 * @param {Object} data
	 * @return {Object} 
	 */
	async update (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/surveys/${id}`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Update survey's segments, fields and options
	 *
	 * @param {String} id id of the survey 
	 * @param {Object} data segments, fields and options 
	 * @return {Object}
	 */
	async updateParts (id, data) {
		try {
			const response = await Http.patch(`api/subscriber/surveys/${id}/survey-parts`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},
	/**
	 * Delete chosen survey
	 *
	 * @param {String} id
	 */
	async delete (id) {
		try {
			return await Http.delete(`api/subscriber/surveys/${id}`)
		} catch (err) {
			throw err
		}
	},

	/**
	 * Upload image
	 *
	 * @param {String} id
	 */
	async uploadImage (id, data) {
		try {
			const response = await Http.post(`api/subscriber/surveys/${id}/uploadImage`, data)
			return response.data
		} catch (err) {
			throw err
		}
	},

		/**
	 * Upload image
	 *
	 * @param {String} id
	 */
		 async uploadBottomImage (id, data) {
			try {
				const response = await Http.post(`api/subscriber/surveys/${id}/uploadBottomImage`, data)
				return response.data
			} catch (err) {
				throw err
			}
		},

			/**
	 * Upload image
	 *
	 * @param {String} id
	 */
			 async uploadFinishImage (id, data) {
				try {
					const response = await Http.post(`api/subscriber/surveys/${id}/uploadFinishImage`, data)
					return response.data
				} catch (err) {
					throw err
				}
			},
	

	/**
	 *
	 * @param {String} id
	 */
	 async removePreviewImage (id) {
		try {
			const response = await Http.post(`api/subscriber/surveys/${id}/removePreviewImage`)
			return response.data
		} catch (err) {
			throw err
		}
	},

	/**
	 *
	 * @param {String} id
	 */
		 async removeBottomImage (id) {
			try {
				const response = await Http.post(`api/subscriber/surveys/${id}/removeBottomImage`)
				return response.data
			} catch (err) {
				throw err
			}
		},

	/**
	 *
	 * @param {String} id
	 */
	async removeFinishImage (id) {
		try {
			const response = await Http.post(`api/subscriber/surveys/${id}/removeFinishImage`)
			return response.data
		} catch (err) {
			throw err
		}
	},

	/**
	 * Update styles
	 *
	 * @param {String} id
	 */
	async updateStyles (id, data) {
		try {
			const response = await Http.post(`api/subscriber/surveys/${id}/updateStyles`, data)
			// console.log(response)
			return response.data
		} catch (err) {
			throw err
		}
	}
}
