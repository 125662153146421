import Vue from 'vue'

export default {
	setFields(state, fields) {
		state.all = fields
	},
	/**
	 * Add a new field to the list
	 * @param {Object} field 
	 */
	add(state, field) {
		state.all = {
			...state.all,
			[field.id]: {
				...field,
				new: true
			}	
		}
	},
	/**
	 * Remove field
	 * @param {String} field_id 
	 */
	remove(state, field_id) {
		if (!state.all[field_id].new) {
			state.removed.push(field_id)
		}
		Vue.delete(state.all, field_id)
	},
	/**
	 * Edit field's value
	 * @param {Object} payload field's id, new value's key and value's value
	 */
	editValue(state, payload) {
		state.all[payload.field] = {
			...state.all[payload.field],
			[payload.key]: payload.value
		}	
		if (
			payload.key === 'label'
			&& payload.value !== ''
		) {
			if (state.notValid.includes(payload.field)) {
				let index = state.notValid.indexOf(payload.field)
				state.notValid.splice(index, 1)
			}
		}
	},
	/**
	 * Edit field's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue(state, payload) {
		state.all[payload.field] = {
			...state.all[payload.field],
			translations: {
				...state.all[payload.field].translations,
				[payload.locale]: {
					[payload.key]: payload.value
				}
			} 		
		}	
		//Remove from not valid is value isn't empty
		if (
			payload.key === 'label'
			&& payload.value !== '' 
		) {
			if (state.notValid.hasOwnProperty(payload.locale) 
				&& state.notValid[payload.locale].includes(payload.field)) {
				let items = state.notValid[payload.locale]
				let index = items.indexOf(payload.field)
				items.splice(index, 1)
				Vue.set(state.notValid, payload.key, items)
			}
		}
	},
	/**
	 * Add a new option to given segment
	 * @param {Object} payload 
	 */
	addOption(state, payload) {
		state.all[payload.field].options.push(payload.option)	
	},
	/**
	 * Remove option from field
	 * @param {Object} option 
	 */
	removeOption(state, option) {
		const index = state.all[option.field].options.indexOf(option.id)	
		if (index !== -1) {
			state.all[option.field].options.splice(index, 1)
		}
	},
	/**
	 * move fields option
	 * @param {Object} payload field_id, option_id, new index 
	 */
	setOptions(state, payload) {
		state.all[payload.field].options = payload.options	
	},
	/**
	 * Restore resently removed field 
	 * @param {Object} field 
	 */
	restoreField(state, field) {
		state.all = {
			...state.all,
			[field.id]: {
				...field
			}
		}	
		const index = state.removed.indexOf(field.id)
		if (index > -1) {
			state.removed.splice(index, 1)
		}
	},	
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
	/**
	 * Add field_id to "not valid" list if it doesnt' pass validation
	 * @param {Object} payload 
	 */
	addToNotValid(state, payload) {
		if (!state.notValid.hasOwnProperty(payload.locale)) {
			state.notValid = {
				...state.notValid,
				[payload.locale]: []
			}
		}
		const index = state.notValid[payload.locale].indexOf(payload.field)
		if (index === -1) {
			state.notValid[payload.locale].push(payload.field)
		}
	},
	/**
	 * Remove item from "not valid" list, making it "valid"
	 * @param {String} item_id 
	 */
	removeFromNotValid(state, item_id) {
		Object.keys(state.notValid).forEach(locale => {
			const index = state.notValid[locale].indexOf(item_id)	
			if (index !== -1) {
				state.notValid[locale].splice(index, 1)
			}
		})
	},
	removePropertyFromNotValid(state, key) {
		if (Object.keys(state.notValid).includes(key)) {
			const { [key]: removedItem, ...restProperties } = state.notValid
			state.notValid = restProperties;
		}
	},
	/**
	 * Clear "notValid" list 
	 */
	clearNotValidList(state) {
		state.notValid = {
			fi: [],
			en: []
		}
	},
}
