export default {
	ageConfirmation: 'Answering the survey requires age over 15. By continuing you confirm to be over this age.',
	chooseOne: 'Choose one to continue',
	chooseOneOrMany: 'Choose one or many',
	endText: 'Survey has ended. Thank you for answering!',
	startText: 'Please, help us improving our services by answering this poll.',
	welcome: 'Welcome!',
	terms: 'This questionnaire is part of tourism data collecting. Data will be used in tourism research and tourism development. Answering this questionnaire is voluntary. No personal data or information will be recorded, given to third parties or used for marketing purposes.',
	lotteryTerms: `<h2>Salmi Platform Oy's Privacy Statement (GDPR compliant)&nbsp; of lottery</h2><p><br />We collect personal information from participants in our lottery to find and reward the winner. We will ask the lottery participant for consent to collect this personal information for the purpose of the lottery.<br />We will keep the personal data of the participants in the lottery in the database of the service provider of Salmi Platform Oy (Hurja Solutions Oy, K&auml;sity&ouml;katu 43, 70100 Kuopio Finland) only until the lottery is announced and the winner is notified.</p><p><br />Access to personal data is strictly controlled and properly protected. Participants' personal information is only used for the purpose of the Salmi Platform Oy's lottery. The personal data will be processed by the person conducting the lottery at Salmi Platform Oy and any authority controlling the lottery. Personal data will not be disclosed to third parties and will not be used for any purpose other than to draw or inform the winner. The person who provided his / her personal information to the lottery may request information about them and ask to erase of his / her personal information prior to the lottery, in which case his / her personal data will be immediately removed from the database.</p><p><br />All participants' personal information is securely deleted after it is no longer needed.</p>`,
	selectLanguage: 'Select language',
	mapSearchText: 'Search here!',
	mapInfoText: 'Which means of transport did you use to reach the destination? Select the starting point of your trip on the map by tapping or enter the starting point in the search. The stages are marked by means of transport. The map asks for the vehicle at the stage.',
	tripDescriptionLabel:'Trip description',
	tripDescriptionInstruction:'Instructions for trip description',
	mapMoveLabel:'Move map',
	mapMoveInstruction:'Instructions for moving the map',
	startPointLabel:'Start point',
	startPointInstruction:'Instructions for start point',
	markerMoveLabel:'Move marker',
	markerMoveInstruction:'Instructions for moving a marker',
	markerDeleteLabel:'Delete Marker',
	markerDeleteInstruction:'Instructions for deleting a marker',
	tripEmissionText:'Your trip emissions',
	showPassagesText:'Show passages',
	closePassagesText:'Close passages',
	tripFinishedText:'Summary of your trip',
	buttonDeleteText: 'Delete',
	buttonCancelText: 'Cancel',
	deleteMapPointHeaderText: 'Are you sure you want to delete the passage?',
	deleteMapPointText: 'Are you sure you want to delete the passage and the following passages?'
}