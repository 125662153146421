const non_navigation_items = [
	{
		path: '/',
		redirect: '/kyselyt'
	},
	{
		path: '/kyselyt/muokkaa',
		redirect: 'kyselyt/muokkaa/kysymykset'
	},
	{
    path: '/kirjaudu',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/LoginView.vue'),
		meta: {
			requiresAuth: false 
		}
  },
  {
    path: '/kyselyt/muokkaa',
    name: 'survey_edit',
    component: () => import('@/views/subscriber/SurveyEditView'),
		children: [
			{
				path: 'kysymykset',
				component: () => import('@/views/subscriber/SurveyEditQuestionsView'),
				meta: {
					title: 'Kysymykset',
					parent: 'survey_edit'
				}
			},
			{
				path: 'asetukset',
				component: () => import('@/views/subscriber/SurveyEditSettingsView'),
				meta: {
					title: 'Asetukset',
					parent: 'survey_edit'
				}
			},
			{
				path: 'tiedot',
				component: () => import('@/views/subscriber/SurveyEditDataView'),
				meta: {
					title: 'Tiedot',
					parent: 'survey_edit'
				}
			}
		],
		meta: {
			title: 'Muokkaa kyselyä',
			parent: 'surveys',
			requiresAuth: true
		}
  },
  {
    path: '/kyselyt/muokkaa/esikatsele',
    name: 'survey_edit_preview',
    component: () => import('@/views/subscriber/SurveyEditPreviewView'),
		meta: {
			title: 'Esikatsele',
			parent: 'survey_edit',
			requiresAuth: true
		}
  },
  {
    path: '/kyselypohjat/muokkaa',
    name: 'survey_template_edit',
    component: () => import('@/views/subscriber/SurveyTemplateEditView'),
    children: [
    	{
    		path: 'kysymykset',
    		component: () => import('@/views/subscriber/SurveyTemplateEditQuestionView'),
				meta: {
					title: 'Kysymykset',
					parent: 'survey_template_edit'
				}
    	},
    	{
    		path: 'asetukset',
    		component: () => import('@/views/subscriber/SurveyTemplateEditSettingsView'),
				meta: {
					title: 'Asetukset',
					parent: 'survey_template_edit'
				}
    	},
    ],
		meta: {
			title: 'Muokkaa kyselypohjaa',
			parent: 'surveys_templates',
			requiresAuth: true
		},
  },
  {
    path: '/kyselypohjat/muokkaa/esikatsele',
    name: 'survey_template_edit_preview',
    component: () => import('@/views/subscriber/SurveyTemplateEditPreviewView'),
		meta: {
			title: 'Esikatsele',
			parent: 'survey_template_edit',
			requiresAuth: true
		}
  },
	{
		path: '/yleiset_kysymyssivut/muokkaa',
		name: 'common_segment_edit',
		component: () => import('@/views/subscriber/common-segment/CommonSegmentEditView'),
		meta: {
			title: 'Muokkaa yleistä kysymystä',
			parent: 'common_segment_index',
			requiresAuth: true
		},
    children: [
    	{
    		path: 'kysymykset',
		    name: 'common_segment_edit_questions',
    		component: () => import('@/views/subscriber/common-segment/CommonSegmentEditQuestionView'),
				meta: {
					title: 'Kysymykset',
					parent: 'common_segment_edit'
				}
    	},
    	{
    		path: 'asetukset',
    		component: () => import('@/views/subscriber/common-segment/CommonSegmentEditSettingsView'),
				meta: {
					title: 'Asetukset',
					parent: 'common_segment_edit'
				}
    	},
    ],
	}

	// Add new non-navigation route
]

const navigation_items = [
	{
    path: '/kyselyt',
    name: 'surveys',
    component: () => import('@/views/subscriber/SurveyIndexView.vue'),
		meta: {
			sort_order: 1,
			title: 'Kyselyt',
			icon: 'mdi-view-list',
			requiresAuth: true
		}
  },
  {
    path: '/kyselypohjat',
    name: 'survey_templates',
    component: () => import('@/views/subscriber/SurveyTemplateIndexView'),
    meta: {
      title: 'Kyselypohjat',
      sort_order: 3,
      icon: 'mdi-text-box',
			requiresAuth: true
    }
  },
	{
		path: '/yleiset_kysymyssivut',
		name: 'common_segment_index',
		component: () => import('@/views/subscriber/common-segment/CommonSegmentIndex'),
		meta: {
			sort_order: 2,
			title: 'Yleiset kysymyssivut',
			icon: 'mdi-text-box',
			requiresAuth: true
		}
	},

  // Add navigation route
]

const navigation_sub_items =  [
	//Add sub-navigation route
]

const routes = [
	...non_navigation_items,
	...navigation_items,
	...navigation_sub_items
]

export {
	navigation_items,
	navigation_sub_items,
	routes
}


