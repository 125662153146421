import { v4 as uuidv4 } from 'uuid';

export default {
	/**
	 * Set jumps 
	 * @param {Object} jumps 
	 */
	setJumps ({ commit }, jumps) {
		commit('setJumps', jumps)
		commit('clearRemoveList')
		commit('resetNotValid')
	},
	 /**
		* Add a new jump 
		* @param {String} from_segment_id
		*/
	 add ({ commit, rootState, getters, rootGetters, dispatch }, from_segment_id) {
		 const id = uuidv4()
		 const jump = {
				id: id,
				[getters.editSource.keyForJump]: rootGetters[`${getters.editSource.base}/data`].id,
				from_segment_id: from_segment_id,
				jump_rule_groups: [],
				to_segment_id: null,
			 	new: true,
			 	jumpToEnd: false
		 }
		 commit('add', jump) 
		 dispatch('ruleGroup/add', id)
	 },
	/**
	 * Remove jump and jump's rule groups
	 * @param {String} jump_id 
	 */
	remove({ state, getters, commit, dispatch }, jump_id) {
		const jump = state.all[jump_id]
		jump.jump_rule_groups.forEach(group_id => {
			dispatch(`${getters.editSource.ruleGroup}/remove`, group_id, { root: true })
		}) 
		commit('remove', jump_id)	
		commit('removeFromNotValid', jump_id)
	},
	/**
	 * Validate single jump 
	 * @param {String} jump_id
	 */
	validateJump ({ state, commit, getters, rootGetters, dispatch }, jump_id) {
		const jump = state.all[jump_id]
		// Validate jump's rules
		const editSource = getters['editSource']	
		const jumpRules = rootGetters[`${editSource.rule}/jumpRules`](jump.jump_rule_groups)
		jumpRules.forEach(rule => {
			dispatch(`${editSource.rule}/validateRule`, rule.id, { root:true })
		})
	},
	/**
	 * Validate jumps 
	 */
	validateJumps ({ state, getters, dispatch }) {
		Object.keys(state.all).forEach(jump_id => {
			dispatch('validateJump', jump_id)
		})	
		//Check that segment's jumps do not have crossing rules
		const segmentJumps = groupBy(_.cloneDeep(getters.all), 'from_segment_id')
	},
}

/**
 * Group by object propety
 *
 * @param {Array} objectArray
 * @param {String} propety
 * @return {Object} Object of groubed arrays 
 */
function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

