import axios from 'axios'

export default {

  /**
   * Fetch all countries.
   *
   * @return Promise
   */
  async all () {
		try {
			const response = await axios.get('/countriesAndCities.json')
			return response.data.map(country => {
				return {
					// code: country.alpha2Code.toLowerCase(),
					code: country.code.toLowerCase(),
					name: country.country,
					cities: country.cities
				}
			})
		} catch (err) {
			throw err
		}
	}
}
