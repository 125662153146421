import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid';

export default {
	/**
	 * set state jumps
	 * @param {Object} jumps 
	 */
	setJumps(state, jumps) {
		state.all = jumps	
	},
	/**
	 * Add the jump to the jumps list 
	 * @param {Object} jump 
	 */
	add(state, jump) {
		state.all = {
			...state.all,
			[jump.id]: {
				...jump
			}
		}	
	},
	/**
	 * remove jump
	 * @param {String} jump_id 
	 */
	remove(state, jump_id) {
		if (!state.all[jump_id].new) {
			state.removed.push(jump_id)
		}
		Vue.delete(state.all, jump_id)
	},
	/**
	 * Edit jump's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.all[payload.jump][payload.key] = payload.value	
		if (payload.key === 'to_segment_id' && payload.value !== null) {
			if (state.notValid.includes(payload.jump)) {
				const index = state.notValid.indexOf(payload.jump)
				state.notValid.splice(index, 1)
			}
		}
	},
	/**
	 * Add a new rule group to the jump
	 * @param {Object} payload 
	 */
	addRuleGroup(state, payload) {
		state.all[payload.jump].jump_rule_groups.push(payload.group)	
	},
	/**
	 * Remove rule group from the jump
	 * @param {Object} group
	 */
	removeRuleGroup(state, group) {
		const index = state.all[group.jump_id].jump_rule_groups.indexOf(group.id)	
		state.all[group.jump_id].jump_rule_groups.splice(index, 1)
	},
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
	/**
	 * Add jump to not valid list
	 * @param {String} jump_id 
	 */
	addToNotValid(state, jump_id) {
		const index = state.notValid.indexOf(jump_id)
		if (index === -1) {
			state.notValid.push(jump_id)
		}
	},
	/**
	 * Remove jump from not valid list
	 * @param {String} jump_id 
	 */
	removeFromNotValid(state, jump_id) {
		if (state.notValid.includes(jump_id)) {
			const index = state.notValid.indexOf(jump_id)
			state.notValid.splice(index, 1)
		}
	},
	/**
	 * Reset not valid list
	 */
	resetNotValid(state,  payload) {
		state.notValid = []	
	},
}
