export default {
  yes: 'Kyllä',
  no: 'Ei',
  cancel: 'Peruuta',
  endpointTitle: 'Oliko tämä matkasi päätepiste?',
  totalEndpointTitle: 'Oliko tämä paluumatkasi päätepiste?',
  sameWayTitle: 'Palasitko samaa reittiä samoilla kulkuvälineillä?',
  selectMotivePower: 'Valitse käyttövoima:',
  selectTransport: 'Millä matkustit?',
  oneWay: 'Yksisuuntainen',
}
