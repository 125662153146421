import mixinMutations from '@/store/mixins/field/mutations'
import mixinActions from '@/store/mixins/field/actions'
import mixinGetters from '@/store/mixins/field/getters'

const source = 'survey/editing'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: [],
		notValid: {
			fi: [],
			en: []
		},
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.template
		}
	},
  mutations: mixinMutations,
	actions: {
		...mixinActions
	} 
}

