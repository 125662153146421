export default {
	/**
	 * Set all categories
	 * @param {Object} categories 
	 */
	setAll(state, categories) {
		state.all = categories
	},
	/**
	 * Set category for edit
	 * @param {Object} payload 
	 */
	setEditing(state, payload) {
		state.editing = payload
	},
	/**
	 * Edit category's value 
	 * @param {Object} payload 
	 */
	editEditingValue(state, payload) {
		state.editing = {
			...state.editing,
			[payload.key]: payload.value
		}	
	},
	/**
	 * Edit create value 
	 * @param {Object} payload 
	 */
	editCreatingValue(state, payload) {
		state.creating = {
			...state.creating,
			[payload.key]: payload.value
		}	
	},
}
