import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import modules from './modules'
import sharedModules from '@/store/shared-modules'
import VuexPersistence from "vuex-persist"
import localforage from 'localforage'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: localforage,
  key: 'salmianalytics',
  asyncStorage: true,
})

export default createStore(Vuex.Store, {
  state: {
    api: 'subscriber',
    editSources: {
      survey: {
        type: 'survey',
        base: 'survey/editing',
        segment: 'survey/editing/segment',
        field: 'survey/editing/field',
        option: 'survey/editing/option',
        jump: 'survey/editing/jump',
        ruleGroup: 'survey/editing/jump/ruleGroup',
        rule: 'survey/editing/jump/rule',
        create: 'survey/creating',
        preview: 'survey/editing/preview',
        keyForJump: 'survey_id'
      },
      template: {
        type: 'template',
        base: 'surveyTemplate/editing',
        segment: 'surveyTemplate/editing/segment',
        field: 'surveyTemplate/editing/field',
        option: 'surveyTemplate/editing/option',
        jump: 'surveyTemplate/editing/jump',
        ruleGroup: 'surveyTemplate/editing/jump/ruleGroup',
        rule: 'surveyTemplate/editing/jump/rule',
        create: 'surveyTemplate/creating',
        preview: 'surveyTemplate/editing/preview',
        keyForJump: 'survey_template_id'
      },
      commonSegment: {
        base: 'commonSegment',
        type: 'commonSegment', 
        segment: 'commonSegment',
        field: 'commonSegment/field',
        option: 'commonSegment/option',
      },
      commonSegmentEdit: {
        base: 'commonSegment/editing',
        type: 'segment-edit',
        segment: 'commonSegment/editing',
        field: 'commonSegment/editing/field',
        option: 'commonSegment/editing/option'
      }
    }
  },
  getters: {
    editSources: state => state.editSources
  },
  modules: {
    ...modules,
    ...sharedModules
  },
  plugins: [vuexLocal.plugin]
});
