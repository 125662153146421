export default {
	all: state => state.all,
	get: state => id => state.all[id],
	jumpRuleGroups: (state) => (jumpRuleGroups) => {
		return jumpRuleGroups.map(id => state.all[id])
	}, 
	/**
	 * Return jump rule groups with nested rules 
	 * @return {Array} 
	 */
	withRules: (state, getters, rootState, rootGetters) => (jumpGroups) => {
		const source = getters.editSource
		const groups = _.cloneDeep(getters['jumpRuleGroups'](jumpGroups))
		return groups.map(group => {
			const groupRules = group.jump_rules
			const rules = rootGetters[`${source.rule}/nestedRules`](groupRules)
			return {
				...group,
				jump_rules: rules,
			}
		})
	},
}
