const getHappeningJumps = (jumps, answers) => {
	let happeningJumps = []
	if (jumps.length > 0) {
		jumps.forEach(jump => {
			let jumpPasses = false
			jump.jump_rule_groups.forEach(group => {
				let groupPasses = true
				group.jump_rules.forEach(rule => {
					if (answers.hasOwnProperty(rule.answer_field_id)) {
					//Answer to the rule's field has been given
						const answer = answers[rule.answer_field_id]  
						if (
							//If answer can't be include to jump rule or
							//or given answer doesn't match given rule option and
							//answers' options list doesn't include anything from rule's option list
							answer.is_extra
							|| (
								rule.options_list.indexOf(answer.option_id) === -1
								&& !rule.options_list.some(option => answer.options.includes(option))
							)
						) {
							groupPasses = false	
						}
					} else {
						//No answer given to the rule's field
						groupPasses = false
					}
				})
				if (groupPasses) {
					//If one group passes, jump happens
					jumpPasses = true
				}
			})
			if (jumpPasses) {
				happeningJumps.push(jump)
			}
		})
	}
	return happeningJumps
}

export {getHappeningJumps}
