export default {
	/**
	 * Set segment to be edited
	 * @param {Object} segment 
	 */
	setEditing(state, segment) {
		state.data = segment
	},
	/**
	 * Edit segment's value 
	 * @param {Object} payload
	 */
	editValue (state, payload) {
		state.data = {
			...state.data,
			[payload.key]: payload.value
		}
		if (
			payload.key === 'title'
			&& payload.value !== ''
		) {
			state.notValid = false
		}
	},
	/**
	 * Edit segment's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue(state, payload) {
		state.data = {
			...state.data,
			translations: {
				...state.data.translations,
				[payload.locale]: {
					[payload.key]: payload.value
				}
			} 		
		}	
		//Remove from not valid is value isn't empty
		if (
			payload.key === 'title'
			&& payload.value !== '' 
		) {
			if (state.notValid[payload.locale]) {
				state.notValid[payload.locale] = false
			}
		}
	},
	/**
	 * Add a new field to given segment
	 * @param {Object} payload segment's id and new field object 
	 */
	addField(state, payload) {
		state.data.fields.push(payload.field)	
	},
	/**
	 * Remove field from segment
	 * @param {Object} field 
	 */
	removeField(state, field) {
		const index = state.data.fields.indexOf(field.id)	
		state.data.fields.splice(index, 1)
	},
	/**
	 * Move segment's fields' positions
	 * @param {Object} payload 
	 */
	moveField(state, payload) {
		state.data.fields = arrayMove(
			state.data.fields,
			payload.fromIndex,
			payload.toIndex
		)
	},	
	/**
	 * Set segment not valid
	 * @param {String} locale
	 */
	setNotValid(state, locale) {
		state.notValid[locale] = true
	},
	/**
	 * Clearn not valid 
	 */
	clearNotValid(state, ) {
		state.notValid = {
			fi: false,
			en: false
		}	
	},
	/**
	 * Change segments locale
	 * @param {String} value 
	 */
	changeLocale(state, value) {
		state.locale = value	
	},
	/**
	 * Change segment's categories
	 * @param {Array} categories 
	 */
	changeCategories(state, categories) {
		state.data = {
			...state.data,
			categories: categories
		}	
	},
	removePropertyFromNotValid(state, key) {
		if (Object.keys(state.notValid).includes(key)) {
			const { [key]: removedItem, ...restProperties } = state.notValid
			state.notValid = restProperties;
		}
	},
}

const arrayMove = (arr, fromIndex, toIndex) => {
    if (toIndex >= arr.length) {
        let k = toIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return arr; 
}
