import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		current: [],
		showing: false,
		allowTimeout: true
	},
	getters: {
		current: state => state.current,
		showing: state => state.showing
  },
	mutations: mutations,
	actions: actions
}
