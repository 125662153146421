export default {
	date: 'Date',
	freeText: 'Free text',
	year: 'Year',
	country: 'Country',
	city: 'City',
	name: 'First name and last name',
	email: 'E-mail',
	wayTo: 'Way to',
	wayBack: 'Way back',
	oneWay: 'One way',
	back: 'Back',
}
