export default {
	all: (state, getters, rootState, rootGetters) => {
		let withCommon = {...state.all, ...state.common}
		return state.segmentList.map(id => withCommon[id])
	}, 
	getByIndex: (state, getters) => (surveyIndex) => {
		return getters.all[surveyIndex]
	},
	/**
	 * Return segments with nested fields 
	 * @return {Array} 
	 */
	withFields (state, getters, rootState, rootGetters) {
		const source = getters.editSource
		const segments = _.cloneDeep(getters.all)
		return segments.map(segment => {
			const segmentFields = segment.fields
			let nestedFields = segment.is_common
				? _.cloneDeep(rootGetters[`commonSegment/field/withOptions`](segmentFields))
				: _.cloneDeep(rootGetters[`${source.field}/withOptions`](segmentFields))
			const translations = Object.keys(segment.translations).map(locale => {
				return {
					..._.cloneDeep(segment.translations[locale]),
					locale: locale
				}
			})
			return {
				...segment,
				fields: nestedFields,
				translations: translations
			}
		})
	},
	invalidValues: state => state.notValid,
	hasInvalidValues (state) {
		let hasInvalidValues = false
		Object.values(state.notValid).forEach(locale => {
			if (locale.length > 0) hasInvalidValues = true
		})
		return hasInvalidValues
	},
	hasInvalidValue: (state) => (locale, segment_id) => {
		if(!state.notValid.hasOwnProperty(locale)) return false
		return state.notValid[locale].includes(segment_id)	
	},
	common: state => Object.keys(state.common).map(key => state.common[key]),
	get: (state, getters) => (segment_id) => {
		return getters.all.find(segment => segment.id === segment_id)
	},
	gapActions: state => state.gapActions, 
	editing: state => state.editing,
	segmentOrderNumber: (state) => (segment_id) => {
		return state.segmentList.indexOf(segment_id) + 1
	},
	nextSegment: (state, getters) => segment_id => {
		const currentSegmentIndex = state.segmentList.indexOf(segment_id)
		if (
			currentSegmentIndex === -1
			|| currentSegmentIndex + 1 === state.segmentList.length
		) {
			return null
		} else {
			return getters.all[currentSegmentIndex + 1]
		}
	},
	previousSegment: state => segment_id => {
		const currentSegmentIndex = state.segmentList.indexOf(segment_id)
		if (
			currentSegmentIndex === -1
			|| currentSegmentIndex - 1 < 0
		) {
			return null
		} else {
			const previousSegmentId = state.segmentList[currentSegmentIndex - 1]
			return state.all[previousSegmentId]
		}
	}
}
