import { v4 as uuidv4 } from 'uuid';

const template = {
	new: true,
	asks_value: false,
	value: null,
	extra_value_type: null,
	children_ids: [],
	label: '',
	translations: {
		fi: {
			label: ''
		},
		en: {
			label: ''
		}
	}
}

export default {
	/**
	 * Set options 
	 * @param {Object} options 
	 */
	setOptions ({ commit, getters }, options) {
		commit('setOptions', options)
		commit('clearRemoveList')
		commit('clearNotValidList')
	},
	/**
	 * Add a new option to the field 
	 * @param {Object} payload 
	 */
	add ({ commit, getters }, payload) {
		const option = {
			id: uuidv4(),
			field: payload.field,
			field_id: payload.field,
			parent_id: null,
			sort_order: payload.sort_order,
			..._.cloneDeep(template)
		}
		commit('add', option)
		commit(`${getters.editSource.field}/addOption`, {
			field: payload.field, 
			option: option.id
		}, { root: true })
	},
	/**
	 * Add copy from the existing option 
	 * @param {Object} payload
	 */
	addCopy ({ state, getters, commit }, payload) {
		const originalOption = state.all[payload.option_id]
		if (originalOption.parent_id === null) {
			const option = {
				..._.cloneDeep(originalOption),
				id: uuidv4(),
				field: payload.field_id,
				children_ids: []
			}
			//If has child options
			if (originalOption.children_ids.length !== 0) {
				originalOption.children_ids.forEach(child_id => {
					const originalChild = state.all[child_id]
					const child = {
						..._.cloneDeep(originalChild),
						id: uuidv4(),
						field: payload.field_id,
						parent_id: option.id
					}
					//Add childoption
					commit('add', child)
					commit(`${getters.editSource.field}/addOption`, {
						field: payload.field_id, 
						option: child.id
					}, { root: true })
					option.children_ids.push(child.id)
				})
			}
			//Add main option
			commit('add', option)
			commit(`${getters.editSource.field}/addOption`, {
				field: payload.field_id, 
				option: option.id
			}, { root: true })
		}
	},
	/**
	 * Add a new child option 
	 * @param {Object} payload
	 */
	addChild ({ commit, getters }, payload) {
		const option = {
			id: uuidv4(),
			field: payload.field,
			field_id: payload.field,
			parent_id: payload.parent_id,
			sort_order: payload.sort_order,
			..._.cloneDeep(template)
		}
		commit('add', option)
		commit('addChild', option)
		commit(`${getters.editSource.field}/addOption`, {
			field: option.field,
			option: option.id
		}, { root: true })
	},
	/**
	 * Remove child option from parent and from the list 
	 * @param {Object} option
	 */
	removeChild ({ commit, getters }, option) {
		commit('removeChild', {
			parent: option.parent_id,
			option: option.id
		})	
		commit(`${getters.editSource.field}/removeOption`, option, { root: true })
		commit('removeFromNotValid', option.id)
		commit('remove', option.id)
	},
	/**
	 * Remove option 
	 * @param {String} option_id 
	 */
	remove ({ state, commit, getters, dispatch }, option_id) {
		const option = state.all[option_id]
		const children = getters.itemOptions(option.children_ids) 
		if (children.length > 0) {
			children.forEach(child => {
				dispatch('removeChild', child)
			})
		}
		commit(`${getters.editSource.field}/removeOption`, option, { root: true })
		commit('remove', option_id)	
		commit('removeFromNotValid', option_id)
	},
	/**
	 * Restory removed options 
	 * @param {Array} options
	 */
	restoreOptions ({ commit, dispatch }, options) {
		options.forEach(option => {
			commit('restoreOption', option)
		})		
		dispatch('validateOptions')
	},
	/**
	 * Edit option's translation value
	 * @param {Object} payload 
	 */
	editTranslationValue({state, commit, getters, rootGetters}, payload) {
		commit('editTranslationValue', {
			option: payload.option,
			key: payload.key,
			value: payload.value,
			locale: rootGetters[`${getters.editSource.base}/locale`]
		})
	},
	/**
	 * Validate all survey's/template's options or single fields options 
	 * @param {Object} field field which options will be validaed
	 */
	validateOptions ({ state, commit, getters, rootGetters }, { field, languages }) {
		//Option is valid if it's label translations are not empty AND if 
		//options's field IS NOT select or multiselect 
		let options = Object.values(state.all)
		if (field) {
			options = getters['itemOptions'](field.options)
		}

		if(!Array.isArray(languages) || !languages.length) {
			languages = ['fi', 'en'];
		}

		// Remove languages outside scope
		Object.keys(state.notValid).forEach(locale => {
			if(!languages.includes(locale)) {
				commit('removePropertyFromNotValid', locale)
			}
		})

		options.forEach(option => {
			const optionField = rootGetters[`${getters.editSource.field}/get`](option.field_id)
			languages.forEach(key => {
			  if(!optionField) {
					commit('removeFromNotValid', option.id)
			  } else if (
					optionField.answer_type !== 'multiselect'
					&& optionField.answer_type !== 'select'
				) {
					commit('removeFromNotValid', option.id)
				} else if (
					optionField.answer_type === 'multiselect'
					&& option.parent_id !== null 
				) {
					commit('removeFromNotValid', option.id)
				} else if (!option.translations[key]
					|| option.translations[key].label === ''
					|| option.translations[key].label === null) {
					commit('addToNotValid', {
						option: option.id,
						locale: key
					})
				}
			})
		})	
	},
}
