import actions from './actions'
import mutations from './mutations'
import showing from './showing'
import creating from './creating'
import editing from './editing'

export default {
	namespaced: true,
	state: {
		all: [],
		showingJumps: {},
		is_fetching: false
	},
	getters: {
		all: state => state.all,
		showing: state => state.showing.data,
		isFetching: state => state.is_fetching
	},
	mutations: mutations,
	actions: actions,
	modules: {
		showing: showing,
		creating: creating,
		editing: editing
	}
}
