import Vue from 'vue'
export default {
	/**
	 * set state rules
	 * @param {Object} rules 
	 */
	setRules(state, rules) {
		state.all = rules
	},
	/**
	 * Add the rule to the rules list 
	 * @param {Object} rule 
	 */
	add(state, rule) {
		state.all = {
			...state.all,
			[rule.id]: {
				...rule
			}
		}
	},
	/**
	 * Remove rule
	 * @param {String} rule_id 
	 */
	remove(state, rule_id) {
		if (!state.all[rule_id].new) {
			state.removed.push(rule_id)
		}
		Vue.delete(state.all, rule_id)
	},
	/**
	 * Edit rule's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		Vue.set(state.all[payload.rule], payload.key, payload.value)
		const rule = state.all[payload.rule]
		if (
			rule.answer_segment_id !== null
			&& rule.answer_field_id !== null
			&& rule.options.length > 0
		) {
			if (state.notValid.includes(payload.rule)) {
				const index = state.notValid.indexOf(payload.rule)
				state.notValid.splice(index, 1)
			}
		}
	},
	/**
	 * Clear list of items to be removed
	 * @param {}  payload 
	 */
	clearRemoveList(state,  payload) {
		state.removed = []	
	},
	/**
	 * Add rule to not valid list
	 * @param {String} rule_id 
	 */
	addToNotValid(state, rule_id) {
		const index = state.notValid.indexOf(rule_id)
		if (index === -1) {
			state.notValid.push(rule_id)
		}
	},
	/**
	 * Remove rule from not valid list
	 * @param {String} rule_id 
	 */
	removeFromNotValid(state, rule_id) {
		if (state.notValid.includes(rule_id)) {
			const index = state.notValid.indexOf(rule_id)
			state.notValid.splice(index, 1)
		}
	},
	/**
	 * Reset not valid list
	 */
	resetNotValid(state,  payload) {
		state.notValid = []	
	},
	/**
	 * Reset rule's values
	 * @param {String} rule_id 
	 */
	resetRule(state, rule_id) {
		const reset = {
			answer_segment_id: null,
			value: null,
			options: []
		}
		Vue.set(state.all, rule_id, {
			...state.all[rule_id],
			...reset
		})
	},
}
