import surveyTarget from './survey-target'
import surveyTemplate from './survey-template'
import survey from './survey'
import surveySection from './survey-section'
import commonSegment from './common-segment'
import category from './category'
import targetType from './target-type'
import language from './language'

export default {
	surveyTarget: surveyTarget,	
	surveyTemplate: surveyTemplate,	
	survey: survey,	
	surveySection: surveySection,
	commonSegment: commonSegment,
	category: category,
	targetType: targetType,
	language: language,
}
