import mixinMutations from '@/store/mixins/category/mutations'
import mixinActions from '@/store/mixins/category/actions'

export default {
	namespaced: true,
	state: {
		all: {},
		creating: {},
		editing: {}
	},
	getters: {
		all: state => Object.keys(state.all).map(key => state.all[key]),
		get: state => category_id => state.all[category_id],
		editing: state => state.editing,
		creating: state => state.creating,
		segmentCategories: (state) => (ids) => {
			let filtered = ids.filter(id => state.all.hasOwnProperty(id))
			return filtered.map(id => state.all[id])
		}
  },
	mutations: {
		...mixinMutations
	},
	actions: {
		...mixinActions
	} 
}
