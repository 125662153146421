import mixinMutations from '@/store/mixins/jump/mutations'
import mixinActions from '@/store/mixins/jump/actions'
import mixinGetters from '@/store/mixins/jump/getters'
import rule from './rule'
import ruleGroup from './rule-group'

export default {
	namespaced: true,
	state: {
		all: {},
		removed: [],
		notValid: []
	},
	getters: {
		...mixinGetters,
		editSource (state, getters, rootState, rootGetters) {
			return rootGetters.editSources.survey
		},
	},
  mutations: mixinMutations,
	actions: {
		...mixinActions
	},
	modules: {
		rule: rule,
		ruleGroup: ruleGroup
	}
}
